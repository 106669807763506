<h2 mat-dialog-title>Ändern Sie Ihr Paket</h2>
<mat-dialog-content>
    <div id="container">
        <div class="row">
            <div>
                <mat-card class="card">
                    <mat-card-header>
                        <mat-card-title>
                            <h1>Basic</h1>
                        </mat-card-title>
                    </mat-card-header>

                    <mat-card-content>
                        <h1>0 €</h1> <b>Benutzer / Monat</b>
                        <mat-divider></mat-divider>

                        <b>Das Basic-Paket beinhaltet:</b>
                        <p>EVA</p>
                        <p>Pre-Check</p>
                        <p>unbegrenzte Betrachteranzahl</p>
                        <p>Dateiablage 1 GB</p>
                        <p>Mobile App</p>
                        <p>1 Bentuzer</p>
                    </mat-card-content>
                </mat-card>
            </div>
            <div>
                <mat-card class="card">
                    <mat-card-header>
                        <mat-card-title>
                            <h1>User</h1>
                        </mat-card-title>
                    </mat-card-header>

                    <mat-card-content>
                        <h1>125 €</h1> <b>Benutzer / Monat</b>
                        <mat-divider></mat-divider>

                        <b>Das User-Paket beinhaltet:</b>
                        <p>EVA</p>
                        <p>Pre-Check</p>
                        <p>unbegrenzte Betrachteranzahl</p>
                        <p>Dateiablage 10 GB</p>
                        <p>Mobile App</p>
                        <p>3 Bentuzer</p>
                        <p>Dokumentenmanagement</p>
                        <p>Aufgabenmanagement</p>
                        <p>Gantt-Chart</p>
                        <p>Audit</p>
                    </mat-card-content>
                </mat-card>
            </div>
            <div>
                <mat-card class="card">
                    <mat-card-header>
                        <mat-card-title>
                            <h1>Projekt</h1>
                        </mat-card-title>
                    </mat-card-header>

                    <mat-card-content>
                        <h1>500 €</h1> <b>Benutzer / Monat</b>
                        <mat-divider></mat-divider>

                        <b>Das Projekt-Paket beinhaltet:</b>
                        <p>EVA</p>
                        <p>Pre-Check</p>
                        <p>unbegrenzte Betrachteranzahl</p>
                        <p><b>Dateiablage 50 GB</b></p>
                        <p>Mobile App</p>
                        <p><b>unbegrenzte Benutzeranzahl</b></p>
                        <p>Dokumentenmanagement</p>
                        <p>Aufgabenmanagement</p>
                        <p>Gantt-Chart</p>
                        <p>Audit</p>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" (click)="checkout()">Weiter zur Kasse</button>
    <button mat-button (click)="closeDialog(false)" cdkFocusInitial>Abbrechen</button>
</mat-dialog-actions>