<div class="center">
  <div class="container">
    <!-- Spalte 1 -->
    <div [formGroup]="newPasswordForm" class="col">
      <div>
        Passwortrichtlinien
        <ul>
          <li>Mindestens 8 Zeichen</li>
          <li>Kleinbuchstabe</li>
          <li>Großbuchstabe</li>
          <li>Zahl</li>
          <li>Sonderzeichen {{ '(@, $, !, %, *, ?, &, +, -, §, €)' }}</li>
        </ul>
      </div>

      <mat-form-field>
        <mat-label>Neues Passwort</mat-label>
        <input matInput #preCheckClp formControlName="newPassword" type="password" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Neues Passwort bestätigen</mat-label>
        <input matInput #preCheckClp formControlName="newPasswordRepeat" type="password" />
        <p *ngIf="newPasswordForm.controls['newPasswordRepeat'].errors"><span>Neue Passwörter nicht identisch</span></p>
      </mat-form-field>
    </div>
  </div>
  <button mat-raised-button color="primary" [disabled]="newPasswordForm.invalid" (click)="save()">Speichern</button>
</div>
