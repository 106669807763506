import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';
import { getAuth, signOut } from 'firebase/auth';
import { IndicatorService } from '../../services/indicator.service';
import { SettingsService } from '../../services/settings.service';
import { SetupService } from '../../services/setup.service';
import { ThemeService } from '../../services/theme.service';
import { ProfilePictureComponent } from '../profile-picture/profile-picture.component';
import { UserService } from '../../services/user.service';
import { takeUntil } from 'rxjs';
import { BaseComponent } from '../base/base.component';
import { User } from '@eeule/eeule-shared/src/types';
import { AnalyticsService } from '../../services/analytics/analytics.service';

@Component({
  selector: 'eule-navigation-profile-bar',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatSlideToggleModule,
    ProfilePictureComponent,
    ReactiveFormsModule,
  ],
  templateUrl: './navigation-profile-bar.component.html',
  styleUrl: './navigation-profile-bar.component.scss',
})
export class NavigationProfileBarComponent extends BaseComponent implements OnInit {
  public toggleControl = new FormControl(false);

  constructor(
    private _settingsService: SettingsService,
    private _router: Router,
    private _setupService: SetupService,
    private _indicatorService: IndicatorService,
    public userService: UserService,
    private _themeService: ThemeService,
    private analyticsService: AnalyticsService
  ) {
    super();
  }

  ngOnInit(): void {
    this.toggleControl.valueChanges.subscribe(darkMode => {
      this.analyticsService.sendEvent('button_click', { label: 'navigation-profile-bar_slide-toggle_bedtime', value: darkMode });
      this._themeService.themeSig.set(darkMode ? 'dark-theme' : 'default-theme');
      this.userService.updateUser(this.userService.euleUser$.value!.id, { darkMode: !!darkMode });
    });
    this.userService.euleUser$.pipe(takeUntil(this.stop$)).subscribe((user: User | null) => {
      if (!user) return;
      this.toggleControl.setValue(!!user!.darkMode);
    });
  }

  public navigate(path: string) {
    this._router.navigate([`/intern/${path}`]);
  }

  public openContactPageInNewTab() {
    window.open('https://www.eeule.de/#kontakt', '_blank');
  }

  public logout() {
    this.analyticsService.sendEvent('button_click', { label: 'navigation-profile-bar_logout' });
    signOut(getAuth())
      .then(() => {
        this._router.navigate(['/']);
      })
      .catch((error: Error) => {
        throw new Error('Error on logging out', error);
      });
  }

  public getUserName(): string {
    return getAuth().currentUser ? getAuth().currentUser!.email! : '';
  }

  public clickProfilePicture() {
    this.analyticsService.sendEvent('button_click', { label: 'navigation-profile-bar_profile-picture' });
  }
}
