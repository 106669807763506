import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AsyncPipe, NgClass, NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterLink } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { BaseComponent } from '../../core/components/base/base.component';
import { AppConfigService } from '../../core/services/appConfig/app-config.service';
import { AuthService } from '../../core/services/auth-christian/auth.service';
import { ForgotPasswordComponent } from '../components/forgot-password/forgot-password.component';

type LoginForm = {
  email: FormControl<string | null>;
  password: FormControl<string | null>;
  persistentLogin: FormControl<boolean | null>;
};

@Component({
  selector: 'eule-login',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgOptimizedImage,
    AsyncPipe,
    NgClass,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatCheckboxModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    RouterLink,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent extends BaseComponent {
  appConfigSignUp$ = this._appConfigService.getLiveAppConfigSignUp().pipe(takeUntil(this.stop$));
  appConfigSignIn$ = this._appConfigService.getLiveAppConfigSignIn().pipe(
    takeUntil(this.stop$),
    tap(() => this.isLoading$.next(false))
  );

  loginForm: FormGroup<LoginForm> = this._formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
    persistentLogin: [false],
  });

  isHandset$: Observable<boolean> = this._breakpointObserver.observe(Breakpoints.HandsetPortrait).pipe(
    map(result => result.matches),
    shareReplay()
  );

  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /** Error state matcher for form validation. */
  errorStateMatcher: ErrorStateMatcher = new ErrorStateMatcher();

  constructor(
    public _authService: AuthService,
    private _formBuilder: FormBuilder,
    private _dialog: MatDialog,
    private _breakpointObserver: BreakpointObserver,
    private _appConfigService: AppConfigService
  ) {
    super();
    this._authService.cleanupOnLoginAndSignup();
  }

  /**
   * Submits the login form.
   */
  async submit() {
    this.isLoading$.next(true);
    await this._authService.login(this.loginForm.value.email, this.loginForm.value.password, !!this.loginForm.value.persistentLogin);
    this.isLoading$.next(false);
  }

  /**
   * Opens the forgot password dialog.
   */
  openForgotPasswordDialog() {
    this._dialog.open(ForgotPasswordComponent, { width: '400px' });
  }
}
