import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { getAuth } from 'firebase/auth';
import { ImageCroppedEvent, ImageCropperModule, LoadedImage } from 'ngx-image-cropper';
import { Observable, from, of, take, takeUntil, tap } from 'rxjs';
import { BaseComponent } from '../../../core/components/base/base.component';
import { SnackbarService } from '../../../core/services/snackbar.service';
import { StorageService, allowedImageExtensions } from '../../../core/services/storage.service';

export interface EditProfilePictureDialogComponentConfig {}

@Component({
  selector: 'eule-edit-profile-picture-dialog',
  standalone: true,
  imports: [ImageCropperModule, CommonModule, MatButtonModule, MatDialogModule, MatIconModule, MatInputModule],
  templateUrl: './edit-profile-picture-dialog.component.html',
  styleUrl: './edit-profile-picture-dialog.component.scss',
})
export class EditProfilePictureDialogComponent extends BaseComponent {
  public profilePicture$: Observable<string | null> = of(null);
  public allowedFileExtensions = allowedImageExtensions.map(ext => `.${ext}`).join(', ');
  imageChangedEvent: Event | null = null;
  croppedImage: SafeUrl | null = null;
  croppedImageBlob: Blob | null | undefined;

  constructor(
    public dialogRef: MatDialogRef<EditProfilePictureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditProfilePictureDialogComponentConfig,
    private sanitizer: DomSanitizer,
    private _storageService: StorageService,
    private _snackbarService: SnackbarService
  ) {
    super();
    this.profilePicture$ = from(this._storageService.downloadProfilePicture(getAuth().currentUser!.uid));
  }

  fileChangeEvent(event: Event | any): void {
    const file: File = event.target.files[0];
    if (file) {
      if (!this._storageService.isImageUploadAllowed(file)) {
        this._snackbarService.showErrorMessage('Dateityp oder Dateigröße nicht erlaubt.');
        throw new Error('Dateityp oder Dateigröße nicht erlaubt.');
      }
      this.imageChangedEvent = event;
    }
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl!);
    this.croppedImageBlob = event.blob!;
  }
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  imageLoaded(image: LoadedImage) {}

  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  public closeDialog(save: boolean): void {
    if (save && this.croppedImageBlob) {
      this._storageService
        .uploadProfilePicture(getAuth().currentUser!.uid, this.croppedImageBlob)
        .pipe(
          takeUntil(this.stop$),
          take(1),
          tap(() => this._storageService.propagateFileChanges())
        )
        .subscribe(() => this.dialogRef.close(true));
    } else {
      this.dialogRef.close(undefined);
    }
  }
}
