<mat-tab-group>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">thumb_up</mat-icon>
            Projekte
        </ng-template>
        <eule-project-list></eule-project-list>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">thumb_up</mat-icon>
            Enterprise
        </ng-template>
        <eule-enterprise-plan></eule-enterprise-plan>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">thumb_up</mat-icon>
            Einstellungen
        </ng-template>

        <eule-invoice-settings></eule-invoice-settings>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">thumb_up</mat-icon>
            Rechnungen
        </ng-template>

        Content 4
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">thumb_up</mat-icon>
            Zahlungsart
        </ng-template>

        Content 5
    </mat-tab>
</mat-tab-group>