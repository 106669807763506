import { Component } from '@angular/core';

@Component({
  selector: 'eule-project-plans-checkout-dialog',
  standalone: true,
  imports: [],
  templateUrl: './project-plans-checkout-dialog.component.html',
  styleUrl: './project-plans-checkout-dialog.component.scss'
})
export class ProjectPlansCheckoutDialogComponent {

}
