import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Pagination, ProjectUser } from '@eeule/eeule-shared/src/types';
import { BehaviorSubject, take } from 'rxjs';
import { TypeSafeMatCellDef } from '../../../core/directives/TypeSafeMatCellDef';
import { ProjectService } from '../../../core/services/project.service';
import { UserService } from '../../../core/services/user.service';
import { MatCardModule } from '@angular/material/card';
import { MatIconButton, MatMiniFabButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from '../../../core/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'eule-users-list',
  standalone: true,
  imports: [CommonModule, MatPaginatorModule, MatTableModule, MatIconModule, TypeSafeMatCellDef, MatProgressSpinnerModule, MatSortModule, MatCardModule, MatIconButton, MatMiniFabButton, MatTooltip],
  templateUrl: './users-list.component.html',
  styleUrl: './users-list.component.scss',
})
export class UsersListComponent implements AfterViewInit{
  @Input({ required: true }) dataSource!: MatTableDataSource<ProjectUser>;
  @ViewChild(MatPaginator) paginator!: MatPaginator | null;
  @ViewChild(MatSort) sort!: MatSort | null;

  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public displayedColumns: string[] = ['name', 'title', 'company', 'phone', 'email', 'license', 'actions'];

  public constructor(
    public projectService: ProjectService,
    public userService: UserService,
    private _dialog: MatDialog
  ) {
  }

  public deleteUserFromProject(user: ProjectUser) {
    this._dialog
      .open<ConfirmDialogComponent, ConfirmDialogData, boolean>(ConfirmDialogComponent, {
        width: '360px',
        data: { dynamicContent: 'Benutzer entfernen' },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(takeAction => {
        if (!takeAction) return;
        if (user.authUserId === this.projectService.project$.value?.projectOwner) throw new Error('Project Owner cannot be deleted from Project');
        this.isLoading$.next(true);
        this.projectService.deleteUserFromProject(this.projectService.project$.value!.id, user).subscribe(() => this.isLoading$.next(false));
    });
  }

  public onChangePageSize(pageSize: Pagination | number) {
    this.userService.updateUser(this.userService.euleUser$.value!.id, { pagination: pageSize as Pagination });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
