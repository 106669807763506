import { Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTabBody, MatTabsModule } from '@angular/material/tabs';
import { getAuth } from 'firebase/auth';
import { ProfilePictureComponent } from '../../core/components/profile-picture/profile-picture.component';
import { ProfileInformationComponent } from '../components/profile-information/profile-information.component';
import { ProfileSecurityComponent } from '../components/profile-security/profile-security.component';
import { MatCard, MatCardContent } from '@angular/material/card';
import { UserService } from '../../core/services/user.service';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'eule-profile-page',
  standalone: true,
  imports: [MatIconModule, MatTabsModule, ProfileInformationComponent, ProfileSecurityComponent, ProfilePictureComponent, MatDialogModule, MatCard, MatCardContent, MatTabBody, AsyncPipe],
  templateUrl: './profile-page.component.html',
  styleUrl: './profile-page.component.scss',
})
export class ProfilePageComponent {
  public authUser = getAuth().currentUser;

  constructor(public _userService: UserService) {
  }
}
