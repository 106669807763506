<h2 mat-dialog-title>Neues Projekt</h2>
@if(isLoading$ | async){
<mat-spinner [diameter]="50"></mat-spinner>
} @else {
<mat-dialog-content [formGroup]="dataForm" class="mat-typography">
  <div class="row">
    <mat-form-field class="w-100-percent no-background smaller-font">
      <mat-label>Bauphase</mat-label>
      <mat-select formControlName="lifeCyclePhase" (selectionChange)="filterDgnbSystems(lifeCyclePhaseSelect.value)"
        #lifeCyclePhaseSelect class="w-100-percent no-background smaller-font">
        @for (phase of lifeCyclePhaseEnum | keyvalue; track phase) {
        <mat-option [value]="phase.key">{{ phase.value }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <!-- TODO: Systems are lifeCyclePhase specific and this dropdown options should depend on prevous contruction phase selection  -->
  @if(lifeCyclePhaseSelect.value){
  <div class="row">
    <mat-form-field class="w-100-percent no-background smaller-font">
      <mat-label>Zertifizierungssystem</mat-label>
      <mat-select formControlName="dgnbSystem" #dgnbSystem>
        @for (system of filteredDgnbSystems; track system) {
        <mat-option [value]="system.id">{{ system.name }} {{ system.certificationVersion }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  }
  <div class="row">
    <mat-form-field class="w-100-percent no-background smaller-font">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>
  </div>
  <div class="row">
    <mat-form-field class="w-100-percent no-background smaller-font">
      <mat-label>Nummer</mat-label>
      <input matInput formControlName="number" />
    </mat-form-field>
  </div>
  <div class="row">
    <mat-slide-toggle formControlName="bgfBigger5000" (change)="toggleBgfBigger5000($event)"></mat-slide-toggle>
    <mat-label>Bruttogeschossfläche > 5.000 m2 BGF</mat-label>
  </div>
  <div class="row">
    <mat-slide-toggle formControlName="withDeconstruction" (change)="toggleCircularEconomy($event)"></mat-slide-toggle>
    <mat-label>Mit Rückbau</mat-label>
  </div>
  <div class="row">
    <mat-slide-toggle formControlName="hasMixedUsageProfiles" #mixedUsageProfiles
      (change)="toggleMixedUsageProfiles($event)"></mat-slide-toggle>
    <mat-label>Mischnutzung</mat-label>
  </div>
  <div class="row">
    <ng-container [formGroup]="getUsageProfileFormGroup(0)">
      <mat-form-field class="w-100-percent no-background smaller-font">
        <mat-label>Hauptnutzung</mat-label>
        <mat-select formControlName="usageProfile">
          @for (profileType of filteredUsageProfiles$ | async; track profileType) {
          <mat-option [value]="profileType.id">{{ usageProfileEnum[profileType.name] }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>
  @for(profiles of usageProfilesArray.controls; track profiles){ @if($index >= 1) {
  <div class="row">
    <ng-container [formGroup]="getUsageProfileFormGroup($index)">
      <mat-form-field class="w-100-percent no-background smaller-font">
        <mat-label>Nebennutzung</mat-label>
        <mat-select formControlName="usageProfile">
          @for (profileType of filteredUsageProfiles$ | async; track profileType) {
          <mat-option [value]="profileType.id">{{ usageProfileEnum[profileType.name] }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>
  } } @if(mixedUsageProfiles.checked) {
  <button mat-raised-button color="primary" (click)="addMixedUsageProfile()">Nebennutzung hinzufügen</button>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close [mat-dialog-close]="undefined">Abbrechen</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="dataForm.value"
    [disabled]="dataForm.invalid">Erstellen</button>
</mat-dialog-actions>
}
