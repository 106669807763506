import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { AuthService } from '../../../core/services/auth-christian/auth.service';

interface ForgotPasswordForm {
  email: FormControl<string | null>;
}

@Component({
  selector: 'eule-forgot-password',
  standalone: true,
  imports: [
    MatDialogContent,
    MatDialogTitle,
    MatDialogActions,
    MatDialogClose,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
})
export class ForgotPasswordComponent {
  forgotPasswordForm: FormGroup<ForgotPasswordForm> = this._formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
  });

  constructor(public _authService: AuthService, private _formBuilder: FormBuilder) {}

  /**
   * Submits the forgot password form.
   */
  async submit() {
    if (this.forgotPasswordForm.valid) {
      const email = this.forgotPasswordForm.get('email')?.value;
      if (!email?.length) return;
      await this._authService.sendPasswordResetEmail(email);
    }
  }
}
