import { Injectable, inject } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { FirebaseService } from './firebase.service';
import { IndicatorService } from './indicator.service';

/**
 * ATTENTION: Only for development purposes. Incluces highly destructive methods that overwrite existing Database collections and documents.
 *
 * @author Severin Klug
 * @export
 * @class SetupService
 */
@Injectable({
  providedIn: 'root',
})
export class SetupService {
  private _firestore: Firestore = inject(Firestore);

  constructor(private _firebaseService: FirebaseService, private _indicatorService: IndicatorService) {}

  // public initializeDngbSystem(
  //   dgnbSystemId: string = uuidv4(),
  //   dngbSubject: DgnbSubjectEnum = DgnbSubjectEnum.ENV,
  //   criteriaGroupId: string = uuidv4(),
  //   catalogueId: string = uuidv4(),
  //   indicatorId: string = uuidv4()
  // ) {
  //   console.log('dgnbSystemId: ', dgnbSystemId);
  //   console.log('dngbSubject: ', dngbSubject);
  //   console.log('criteriaGroupId: ', criteriaGroupId);
  //   console.log('catalogueId: ', catalogueId);
  //   console.log('indicatorId: ', indicatorId);

  //   return this._indicatorService.v2SetDngbSystem(dgnbSystemId, {}).pipe(
  //     switchMap(() => {
  //       console.log('-> v2SetCriteriaGroup');
  //       return this._indicatorService.v2SetCriteriaGroup(dgnbSystemId, dngbSubject, {
  //         id: criteriaGroupId,
  //         index: 0,
  //         name: dngbSubject.toUpperCase() + '  1',
  //       });
  //     }),
  //     switchMap(() => {
  //       console.log('-> v2SetIndicator');
  //       return this._indicatorService.v2SetIndicator(dgnbSystemId, dngbSubject, criteriaGroupId, {
  //         id: indicatorId,
  //         index: 0,
  //         level: 0,
  //         name: 'Dies ist ein konkreter Indikator',
  //         number: 1,
  //         checked: false,
  //       } as any);
  //     }),
  //     switchMap(() => {
  //       console.log('-> v2SetIndicatorCatalogue');
  //       return this._indicatorService.v2SetIndicatorCatalogue(dgnbSystemId, dngbSubject, criteriaGroupId, {
  //         id: catalogueId,
  //         indicatorIds: [indicatorId],
  //         name: 'Unwichtiger Faktor',
  //       });
  //     })
  //   );
  // }
}
