import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'eule-tool',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tool.component.html',
  styleUrl: './tool.component.scss'
})
export class ToolComponent {

}
