import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { GeneralTitleComponent } from '../../../core/components/general-title/general-title.component';
import { SearchBarComponent } from '../../../core/components/search-bar/search-bar.component';
import { BaseComponent } from '../../../core/components/base/base.component';
import { ProjectService } from '../../../core/services/project.service';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';

export type ScenarioForm = {
  scenario: FormControl<string | null>
}

@Component({
  selector: 'eule-audit-start-page',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatInputModule, FormsModule, GeneralTitleComponent, SearchBarComponent, MatOptionModule, MatSelectModule, ReactiveFormsModule, MatCardModule],
  templateUrl: './audit-start-page.component.html',
  styleUrl: './audit-start-page.component.scss',
})
export class AuditStartPageComponent extends BaseComponent {
  /** Form group for selecting pre-check scenario */
  scenarioForm: FormGroup<ScenarioForm> = this._formBuilder.group({
    scenario: [''],
  });
  // TODO: has to be discussed (differentiation between preCheck and audit)
  // scenarioOptions$: Observable<MatSelectOption[]> = this._projectService.retrieveScenariosAsMatOptionsAndSelect();

  constructor(private _formBuilder: FormBuilder,
              public _projectService: ProjectService) {
    super();
  }

  public scenario() {
  }

}
