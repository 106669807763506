import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'eule-invoice-settings',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatIconModule, MatInputModule, MatSelectModule, ReactiveFormsModule, MatButtonModule],
  templateUrl: './invoice-settings.component.html',
  styleUrl: './invoice-settings.component.scss',
})
export class InvoiceSettingsComponent {
  public constructor(private _formBuilder: FormBuilder) {}

  public dataForm: FormGroup = this._formBuilder.group({
    firstName: this._formBuilder.control(null),
    lastName: this._formBuilder.control(null),
    companyName: this._formBuilder.control(null),
    street: this._formBuilder.control(null),
    houseNumber: this._formBuilder.control(null),
    city: this._formBuilder.control(null),
    postcode: this._formBuilder.control(null),
    country: this._formBuilder.control(null),
    vatId: this._formBuilder.control(null),
  });

  public save() {}
}
