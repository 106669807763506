import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ProjectService } from '../../services/project.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectLoaderGuard implements CanActivate {
  public constructor(private _projectService: ProjectService) {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Zugriff auf Routendaten
    const projectId = next.params['id']; // Annahme: Die Route hat eine Parameter 'id'

    // FIXME: gut, dass es im guard bei der route aufgerufen wird, aber es muss noch dynamisch anhand der pdoject id aufgerufen werden
    if (projectId) {
      this._projectService.initProjectListeners(projectId);
    } // FIXME: this needs to activate on active route

    return true;
  }
}
