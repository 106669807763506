import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { FeedbackDialogComponentResult } from '../../global/components/feedback-dialog/feedback-dialog.component';
import { Observable, from } from 'rxjs';
import { doc, setDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { BugReportDialogComponentResult } from '../../global/components/bug-report-dialog/bug-report-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  constructor(private _firebaseService: FirebaseService) {}

  public setFeedback(feedback: FeedbackDialogComponentResult): Observable<void> {
    const _feedback: FeedbackDialogComponentResult = { ...feedback, id: uuidv4() };
    const docRef = doc(this._firebaseService.firestore, `feedback/${_feedback.id}`);
    return from(setDoc(docRef, _feedback));
  }

  public setBugReport(bugReport: BugReportDialogComponentResult): Observable<void> {
    const _bugReport: BugReportDialogComponentResult = { ...bugReport, id: uuidv4() };
    const docRef = doc(this._firebaseService.firestore, `bugReport/${_bugReport.id}`);
    return from(setDoc(docRef, _bugReport));
  }
}
