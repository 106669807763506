import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ProjectPlansCheckoutDialogComponent } from '../project-plans-payment-dialog/project-plans-checkout-dialog.component';

@Component({
  selector: 'eule-project-plans-dialog',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, MatInputModule, MatSelectModule, MatCardModule, MatDialogModule, MatDividerModule],
  templateUrl: './project-plans-dialog.component.html',
  styleUrl: './project-plans-dialog.component.scss',
})
export class ProjectPlansDialogComponent {
  public constructor(private _dialogRef: MatDialogRef<ProjectPlansDialogComponent, boolean>, private _projectPlansCheckoutDialog: MatDialog) {}

  public checkout() {
    const dialogRef = this._projectPlansCheckoutDialog.open<ProjectPlansCheckoutDialogComponent, boolean, boolean>(ProjectPlansCheckoutDialogComponent, {
      width: '50vw',
      maxWidth: '50vw',
      height: '50vh',
    });

    dialogRef.afterClosed().subscribe((result: boolean | undefined) => {
      console.info(result);
    });
  }

  public closeDialog(res: boolean) {
    this._dialogRef.close(res);
  }
}
