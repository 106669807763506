

/**
 * @deprecated unnecessary
 * 
 * @export
 * @enum {number}
 */
export enum BooleanEnum {
    true = 'Ja',
    false = 'Nein'
}

