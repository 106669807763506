import { NestedTreeControl } from '@angular/cdk/tree';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, inject, Injector, OnInit } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { ActivatedRoute, NavigationEnd, Router, RouterModule, RouterOutlet } from '@angular/router';
import { getAuth, signOut } from 'firebase/auth';
import { filter, map, merge, Observable, of, shareReplay, take } from 'rxjs';
import { FooterComponent } from '../components/footer/footer.component';
import { NavigationProfileBarComponent } from '../components/navigation-profile-bar/navigation-profile-bar.component';
import { ThemeService } from '../services/theme.service';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ProjectService } from '../services/project.service';

interface FoodNode {
  name: string;
  path?: string;
  nav?: () => void;
  children?: FoodNode[];
}

@Component({
  selector: 'eule-navigation',
  standalone: true,
  imports: [
    CommonModule,
    MatSidenavModule,
    MatDividerModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    RouterOutlet,
    RouterModule,
    FooterComponent,
    MatTreeModule,
    NavigationProfileBarComponent,
    MatToolbarModule,
    NgOptimizedImage,
  ],
  templateUrl: './navigation.component.html',
  styleUrl: './navigation.component.scss',
})
export class NavigationComponent implements OnInit {
  private _injector = inject(Injector);
  public theme$ = toObservable(this._themeService.themeSig, {
    injector: this._injector,
  });

  public PRE_CHECK_TREE_DATA: FoodNode[] = [
    {
      name: 'Pre-Check',
      path: '/audit/pre-check/overview',
      nav: () => this.navigateInProject('audit/pre-check/overview'), // TODO: Navigate to Overview Page
      children: [
        {
          name: 'Ökologische Qualität',
          path: '/audit/pre-check/env',
          nav: () => this.navigateInProject('audit/pre-check/env'),
        },
        {
          name: 'Ökonomische Qualität',
          path: '/audit/pre-check/eco',
          nav: () => this.navigateInProject('audit/pre-check/eco'),
        },
        {
          name: 'Soziokulturelle Qualität',
          path: '/audit/pre-check/soc',
          nav: () => this.navigateInProject('audit/pre-check/soc'),
        },
        {
          name: 'Technische Qualität',
          path: '/audit/pre-check/tec',
          nav: () => this.navigateInProject('audit/pre-check/tec'),
        },
        {
          name: 'Prozessqualität',
          path: '/audit/pre-check/pro',
          nav: () => this.navigateInProject('audit/pre-check/pro'),
        },
        {
          name: 'Standortqualität',
          path: '/audit/pre-check/site',
          nav: () => this.navigateInProject('audit/pre-check/site'),
        },
      ],
    },
  ];

  preCheckTreeControl = new NestedTreeControl<FoodNode>(node => node.children);
  preCheckDataSource = new MatTreeNestedDataSource<FoodNode>();

  public AUDIT_TREE_DATA: FoodNode[] = [
    {
      name: 'Audit',
      path: '/audit/audit-details/overview',
      nav: () => this.navigateInProject('audit/pre-check/overview'), // TODO: Navigate to Overview Page
      children: [
        {
          name: 'Ökologische Qualität',
          path: '/audit/audit-details/env',
          nav: () => this.navigateInProject('audit/audit-details/env'),
        },
        {
          name: 'Ökonomische Qualität',
          path: '/audit/audit-details/eco',
          nav: () => this.navigateInProject('audit/audit-details/eco'),
        },
        {
          name: 'Soziokulturelle',
          path: '/audit/audit-details/soc',
          nav: () => this.navigateInProject('audit/audit-details/soc'),
        },
        {
          name: 'Technische Qualität',
          path: '/audit/audit-details/tec',
          nav: () => this.navigateInProject('audit/audit-details/tec'),
        },
        {
          name: 'Prozessqualität',
          path: '/audit/audit-details/pro',
          nav: () => this.navigateInProject('audit/audit-details/pro'),
        },
        {
          name: 'Standortqualität',
          path: '/audit/audit-details/site',
          nav: () => this.navigateInProject('audit/audit-details/site'),
        },
      ],
    },
  ];

  auditTreeControl = new NestedTreeControl<FoodNode>(node => node.children);
  auditDataSource = new MatTreeNestedDataSource<FoodNode>();

  public isMainDrawerExtraItems: boolean = false;

  // public routeId: Observable<string> = this.router.events.pipe(
  //   map(() => this._route.firstChild?.snapshot.params['id']),
  //   shareReplay(1),
  // ); // FIXME: bad way to get active project from route

  public routeChild$: Observable<string | undefined> = new Observable<string | undefined>();
  // public routeChild$: Observable<string | undefined>
  // = this.router.events.pipe(map(() => this._route.firstChild?.snapshot.firstChild?.routeConfig?.path));

  public routeId$: Observable<string | undefined> = new Observable<string | undefined>();
  // public routeId$ = this.routeId.pipe(take(1));

  constructor(public _router: Router,
              public _themeService: ThemeService,
              public _projectService: ProjectService,
              private _route: ActivatedRoute) {
    this.preCheckDataSource.data = this.PRE_CHECK_TREE_DATA;
    this.preCheckTreeControl.dataNodes = this.PRE_CHECK_TREE_DATA;
    this.auditDataSource.data = this.AUDIT_TREE_DATA;
    this.auditTreeControl.dataNodes = this.AUDIT_TREE_DATA;
  }

  ngOnInit() {
    this.routeChild$ = this.createRouteChildObservable();
    this.routeId$ = this.createRouteIdObservable();
    const url = this._router.url;

    if (url.includes('pre-check')) {
        this.preCheckTreeControl.expandAll();
    }

    if (url.includes('audit-details')) {
      this.auditTreeControl.expandAll();
    }
  }

  hasChild = (_: number, node: FoodNode) => !!node.children && node.children.length > 0;

  public navigateInProject(route: string) {
    this.routeId$.pipe(take(1)).subscribe(id => this._router.navigate([`./intern/project/${id}/${route}`]));
  }

  public clickLogout() {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        this._router.navigate(['/']);
      })
      .catch((error: Error) => {
        this._router.navigate(['/']);
        throw new Error('Error' + error);
      });
  }

  private createRouteChildObservable(): Observable<string | undefined> {
    const initialPath$ = of(this._route.snapshot.firstChild?.firstChild?.routeConfig?.path);

    const routeChangePath$ = this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this._route.firstChild?.snapshot.firstChild?.routeConfig?.path),
    );

    return merge(initialPath$, routeChangePath$);
  }

  private createRouteIdObservable(): Observable<string | undefined> {
    const initialId$ = of(this._route.snapshot.firstChild?.params['id']);

    const routeChangeId$ = this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this._route.firstChild?.snapshot.params['id']),
    );

    return merge(initialId$, routeChangeId$).pipe(shareReplay(1));
  }
}
