import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { EmailAuthProvider, User, getAuth } from 'firebase/auth';
import { AnalyticsService } from '../../../core/services/analytics/analytics.service';
import { AuthService } from '../../../core/services/auth.service';
import { ShakeService, shake } from '../../../core/services/helper/shake-service.service';
import { UserService } from '../../../core/services/user.service';
import { NewPasswordDialogComponent, NewPasswordDialogComponentConfig } from '../new-password-dialog/new-password-dialog.component';

interface CurrentPasswordForm {
  currentPassword: FormControl<string | null>;
}

type DataTrackingForm = {
  GA_baselineReports: FormControl<boolean | null>;
};

export function createPasswordStrengthValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const newPasswordRepeatValue = control.value;
    const newPassword = control.parent?.get('newPassword');

    if (!newPassword) {
      return { passwordMatch: true };
    }
    if (!newPassword.value) {
      return null;
    }
    if (!newPasswordRepeatValue) {
      return null;
    }
    const passwordValid = newPassword.value === newPasswordRepeatValue;

    return !passwordValid ? { passwordMatch: true } : null;
  };
}
@Component({
  selector: 'eule-profile-security',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule, MatButtonModule, MatDialogModule, MatCheckboxModule],
  templateUrl: './profile-security.component.html',
  styleUrl: './profile-security.component.scss',
  animations: [shake], // https://stackoverflow.com/a/74749624
})
export class ProfileSecurityComponent {
  public currentPasswordForm: FormGroup<CurrentPasswordForm> = this._formBuilder.group({
    currentPassword: this._formBuilder.control('', Validators.required),
  });

  public dataTrackingForm: FormGroup<DataTrackingForm> = this._formBuilder.group({
    GA_baselineReports: [false],
  });

  public constructor(
    private _newPasswordDialog: MatDialog,
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
    public shakeService: ShakeService,
    private _userService: UserService,
    private analyticsService: AnalyticsService
  ) {
    const GA_baselineReports: boolean =
      (this._userService.euleUser$.value && this._userService.euleUser$.value.analytics && this._userService.euleUser$.value.analytics['GA_baselineReports']) ||
      false;
    this.dataTrackingForm = this._formBuilder.group({
      GA_baselineReports: [GA_baselineReports],
    });
  }

  public reAuthenticate() {
    console.error('fehler');
    const _currentuser: User | null = getAuth().currentUser;
    if (!_currentuser || !_currentuser.email) {
      throw new Error('No AuthUser or email');
    }
    if (!this.currentPasswordForm.get('currentPassword')!.value) {
      throw new Error('No Password entered');
    }
    const credential = EmailAuthProvider.credential(_currentuser.email!, this.currentPasswordForm.get('currentPassword')!.value!);
    this._authService.reauthenticateEuleUser(credential).subscribe({
      next: () => {
        const dialogRef = this._newPasswordDialog.open<NewPasswordDialogComponent, NewPasswordDialogComponentConfig, boolean | undefined>(
          NewPasswordDialogComponent,
          {
            width: '50vw',
            maxWidth: '50vw',
            height: '50vh',
            data: {},
          }
        );

        dialogRef.afterClosed().subscribe((result: boolean | undefined) => {
          console.info('afterClosed', result);
        });
      },
      error: () => {
        this.shakeService.toggle();
      },
    });
  }

  public sendDataTrackingForm() {
    this.analyticsService.sendEvent('button_click', {
      label: 'profile-security_button_dataTrackingForm',
      value: !!this.dataTrackingForm.controls.GA_baselineReports.value,
    });
    this._userService
      .updateUser(this._userService.euleUser$.value!.id, {
        analytics: { GA_baselineReports: !!this.dataTrackingForm.controls.GA_baselineReports.value },
      })
      .subscribe(() => this.dataTrackingForm.reset({ GA_baselineReports: !!this.dataTrackingForm.controls.GA_baselineReports.value }));
  }
}
