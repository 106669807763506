<h2 mat-dialog-title>Passwort zurücksetzen</h2>
<form class="login-form flexbox direction-column" [formGroup]="forgotPasswordForm" (ngSubmit)="submit()">
  <mat-dialog-content>
    <p>Geben Sie bitte eine E-Mail Adresse an</p>
    <p>Es wird Ihnen ein Link zum Zurücksetzen Ihres Passworts zugesendet</p>
    <mat-form-field class="mail w-100-percent no-background smaller-font email">
      <mat-label>E-Mail</mat-label>
      <mat-icon matPrefix>email</mat-icon>
      <input matInput formControlName="email" type="email">
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions class="dialog-actions">
    <button mat-stroked-button mat-dialog-close>Abbrechen</button>
    <button mat-flat-button color="primary" [disabled]="!forgotPasswordForm.valid">
      <span class="button-label">Absenden</span>
    </button>
  </mat-dialog-actions>
</form>
