<div [formGroup]="dataForm" class="container">
  <!-- Spalte 1 -->
  <div class="col">
    <div class="row">
      <mat-form-field>
        <mat-label>Vorname</mat-label>
        <input matInput #preCheckClp formControlName="firstName" />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field>
        <mat-label>Name des Unternehmens</mat-label>
        <input matInput #preCheckClp formControlName="companyName" />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field>
        <mat-label>Ort</mat-label>
        <input matInput #preCheckClp formControlName="city" />
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field>
        <mat-label>Land</mat-label>
        <input matInput #preCheckClp formControlName="country" />
      </mat-form-field>
    </div>
  </div>

  <!-- Spalte 2 -->
  <div class="col">
    <div class="row">
      <mat-form-field>
        <mat-label>Nachname</mat-label>
        <input matInput #preCheckClp formControlName="lastName" />
      </mat-form-field>
    </div>

    <div class="row">
      <div class="column">
        <div class="row">
          <mat-form-field>
            <mat-label>Straße</mat-label>
            <input matInput #preCheckClp formControlName="street" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>Hausnummer</mat-label>
            <input matInput #preCheckClp formControlName="houseNumber" />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row">
      <mat-form-field>
        <mat-label>Postleitzahl</mat-label>
        <input matInput #preCheckClp formControlName="postcode" />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field>
        <mat-label>Umsatzsteuer-ID</mat-label>
        <input matInput #preCheckClp formControlName="vatId" />
      </mat-form-field>
    </div>
  </div>
</div>

<button mat-raised-button color="primary" (click)="save()">Speichern</button>
