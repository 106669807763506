import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MixedUsageProfile, Project } from '@eeule/eeule-shared/src/types';
import { BehaviorSubject, distinctUntilChanged, takeUntil } from 'rxjs';
import { BaseComponent } from '../../../core/components/base/base.component';
import { CommaDecimalInputComponent } from '../../../core/components/decimal-comma/decimal-comma.component';
import { GeneralTitleComponent } from '../../../core/components/general-title/general-title.component';
import {
  UploadCroppedImageDialogComponent,
  UploadCroppedImageDialogComponentConfig,
} from '../../../core/components/upload-cropped-image-dialog/upload-cropped-image-dialog.component';
import { SafeHtmlPipe } from '../../../core/pipes/safe-html.pipe';
import { AnalyticsService } from '../../../core/services/analytics/analytics.service';
import { IndicatorService } from '../../../core/services/indicator.service';
import { ProjectService } from '../../../core/services/project.service';
import { StorageService } from '../../../core/services/storage.service';
import { BooleanEnum } from '../../../enums/BooleanEnum.enum';
import { CertificationTypeEnum } from '../../../enums/CertificationType.enum';
import { CertificationVersionEnum } from '../../../enums/CertificationVersion.enum';
import { LifeCyclePhaseEnum } from '../../../enums/LifeCyclePhase.enum';
import { ProjectStatusEnum } from '../../../enums/ProjectStatus.enum';
import { UsageProfileEnum } from '../../../enums/UsageProfile.enum';

interface TotalForm {
  bgf: FormControl<number | null>;
  bri: FormControl<number | null>;
  nrf: FormControl<number | null>;
  nuf: FormControl<number | null>;
  tf: FormControl<number | null>;
  vf: FormControl<number | null>;
  kgf: FormControl<number | null>;
  adgnb: FormControl<number | null>;
  nfVehicle: FormControl<number | null>;
  vfHallCorridor: FormControl<number | null>;
  percentage: FormControl<number | null>;
}
interface ProjectForm {
  id: FormControl<string | null>;
  name: FormControl<string | null>;
  number: FormControl<string | null>;
  applicationNumber: FormControl<string | null>;
  description: FormControl<string | null>;
  addressStreet: FormControl<string | null>;
  addressNumber: FormControl<string | null>;
  addressStreetAdditionalInfo: FormControl<string | null>;
  addressPostCode: FormControl<string | null>;
  addressCity: FormControl<string | null>;
  addressState: FormControl<string | null>;
  addressCountry: FormControl<string | null>;
  costs: FormControl<number | null>;
  projectImagePath: FormControl<string | null>;

  leistungsPhasen: FormArray;
  handoverDate: FormControl<Date | null>;
  submissionDate: FormControl<Date | null>;

  lifeCyclePhase: FormControl<LifeCyclePhaseEnum | null>;
  certificationType: FormControl<CertificationTypeEnum | null>;
  certificationVersion: FormControl<string | null>;

  hasMixedUsageProfiles: FormControl<boolean | null>;
  usageProfiles: FormArray;
  numberOfFloors: FormControl<number | null>;
  numberOfUndergroundFloors: FormControl<number | null>;
  numberOfParkingSpaces: FormControl<number | null>;
  numberOfResidentialUnits: FormControl<number | null>;
  numberOfWorkspaces: FormControl<number | null>;
  numberOfUsers: FormControl<number | null>;
  bwzNr: FormControl<number | null>;
  lcaClass: FormControl<string | null>;
  typeOfCommercialSpaces: FormControl<string | null>;

  qng: FormControl<boolean | null>;
  euTaxonomy: FormControl<boolean | null>;
  projectOwner: FormControl<string | null>;
  creator: FormControl<string | null>;
  auditor: FormControl<string | null>;
  supporter: FormControl<string | null>;

  dgnbSystem: FormControl<string | null>;
  status: FormControl<ProjectStatusEnum | null>;
  bgfBigger5000: FormControl<boolean | null>;
  bgfSmaller5000: FormControl<boolean | null>;
  withDeconstruction: FormControl<boolean | null>;
  withoutDeconstruction: FormControl<boolean | null>;
}

interface UsageProfileForm {
  isMainUsage: FormControl<boolean | null>;
  usageProfile: FormControl<string | null>;
  bgf: FormControl<number | null>;
  bri: FormControl<number | null>;
  nrf: FormControl<number | null>;
  nuf: FormControl<number | null>;
  tf: FormControl<number | null>;
  vf: FormControl<number | null>;
  kgf: FormControl<number | null>;
  adgnb: FormControl<number | null>;
  nfVehicle: FormControl<number | null>;
  vfHallCorridor: FormControl<number | null>;
  percentage: FormControl<number | null>;
}

export function lphDateNotBeforePreviousValidator(
  lphIndex: number,
  leistungsPhasenArray: FormArray<FormControl<number>>,
  handoverDateControl: FormControl
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }
    let valid: boolean = true;
    for (let i = 0; i < lphIndex; i++) {
      const element = new Date(leistungsPhasenArray.at(i)?.value).valueOf();
      if (new Date(control.value).valueOf() < element) {
        valid = false;
      }
      if (handoverDateControl.value && new Date(control.value).valueOf() > new Date(handoverDateControl.value).valueOf()) {
        valid = false;
      }
    }

    return !valid ? { lphDatebefore: true } : null;
  };
}

export interface IPhase {
  title: string;
  startDate: number | null; // Timestamp
}
export interface Tooltip {
  [key: string]: string;
}
@Component({
  // changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'eule-project-info-page',
  standalone: true,
  imports: [
    CommonModule,
    CommaDecimalInputComponent,
    GeneralTitleComponent,
    MatButtonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatCardModule,
    SafeHtmlPipe,
  ],
  templateUrl: './project-info-page.component.html',
  styleUrl: './project-info-page.component.scss',
})
export class ProjectInfoPageComponent extends BaseComponent implements OnInit {
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public projectImage$: BehaviorSubject<string | null | undefined> = new BehaviorSubject<string | null | undefined>(
    './../../../../assets/images/Rendering_Mehrfamilienhaus3.jpeg'
  );

  /**
   * TODO: This should be a i18 table
   *
   * @type {Tooltip}
   * @memberOf ProjectInfoPageComponent
   */
  public tooltips: Tooltip = {
    hasMixedUsageProfiles: `Mischnutzung
    Befinden sich im betrachteten Gebäude mehrere unterschiedliche Nutzungen, muss überprüft werden, welches Nutzungsprofil anzuwenden ist. Grundlage für die Einschätzung, ob die Zertifizierung nach MIX23 stattfinden muss, bildet die DGNB Bemessungsfläche.
    Es wird empfohlen die Einstufungen unterschiedlicher Nutzungen mit der DGNB Geschäftsstelle abzustimmen.
    Weiterführende Informationen finden Sie in dem Dokument Anwendungsregeln zur Mischnutzung, Version 23 (MIX23) von der DGNB.`,
    sideUsage: `Eine oder mehrere Nutzungen, die einem anderen Nutzungsprofil als der Hauptnutzung zugeordnet werden und deren Flächenanteil an der gesamten DGNB Bemessungsfläche ≥ 15% beträgt, wird als Nebennutzung bezeichnet. Die Flächen einer Nebennutzung müssen mit dem entsprechenden Nutzungsprofil bewertet werden. `,
    mainUsage: `Das Nutzungsprofil mit dem größten Flächenanteil an der gesamten DGNB Bemessungsfläche wird als Hauptnutzung bezeichnet. Ist die Einstufung nicht eindeutig möglich, ist die Hauptnutzung festzulegen und die Entscheidung zu begründen.`,
    flaechenberechnungNachDin277: `Flächenberechnung nach DIN 277
    BGF(R) = KGF(R) + NRF(R)    
    NRF(R) = NUF(R) + TF(R) + VF(R)
    BGF                Brutto-Grundfläche
    KGF                Konstruktions-Grundfläche
    NRF                Netto-Raumfläche
    NUF                Nutzungsfläche
    TF                 Technikfläche
    VF                 Verkehrsfläche`,
    bemessungsflaeche: `DGNB Bemessungsfläche
    ADGNB = NUFa - NUF a,7,4 + VFa,9,1
    ADGNB                 DGNB Bemesungsfläche
    NUFa                  Nutzungsfläche nach DIN277
    NUF a,7,4            Fahrzeugabstellfläche nach DIN 277
    VFa,9,1                Verkehrsfläche Flure und Hallen nach DIN277
    Weiterführende Informationen finden Sie in dem Dokument Anwendungsregeln zur Mischnutzung, Version 23 (MIX23) von der DGNB.`,
  };

  public lifeCyclePhaseEnum: typeof LifeCyclePhaseEnum = LifeCyclePhaseEnum;
  public usageProfileEnum: typeof UsageProfileEnum = UsageProfileEnum;
  public certificationTypeEnum: typeof CertificationTypeEnum = CertificationTypeEnum;
  public certificationVersionEnum: typeof CertificationVersionEnum = CertificationVersionEnum;
  public booleanEnum: typeof BooleanEnum = BooleanEnum;
  public filterValue: string = '';

  public staticLeistungsphasen: Array<IPhase> = [
    { title: 'Lph1: Grundlagenermittlung', startDate: null },
    { title: 'Lph2: Vorplanung', startDate: null },
    { title: 'Lph3: Entwurfsplanung', startDate: null },
    { title: 'Lph4: Genehmigungsplanung', startDate: null },
    { title: 'Lph5: Ausführungsplanung', startDate: null },
    { title: 'Lph6: Vorbereitung der Vergabe', startDate: null },
    { title: 'Lph7: Mitwirkung der Vergabe', startDate: null },
    { title: 'Lph8: Objektüberwachung', startDate: null },
    { title: 'Lph9: Objektbetreuung', startDate: null },
  ];

  /**
   * This form holds the combined values of all corresponding useageProfiles values
   *
   * @type {FormGroup}
   * @memberOf ProjectInfoPageComponent
   */
  public totalForm: FormGroup<TotalForm> = this._formBuilder.group({
    bgf: this._formBuilder.control({ value: 0, disabled: true }), // Combined Value of kgf + nrf
    bri: this._formBuilder.control({ value: 0, disabled: true }),
    nrf: this._formBuilder.control({ value: 0, disabled: true }), // Combined Value of nf + tf + vf
    nuf: this._formBuilder.control({ value: 0, disabled: true }),
    tf: this._formBuilder.control({ value: 0, disabled: true }),
    vf: this._formBuilder.control({ value: 0, disabled: true }),
    kgf: this._formBuilder.control({ value: 0, disabled: true }),
    adgnb: this._formBuilder.control({ value: 0, disabled: true }), // Combined Value of nfVehicle + vfHallCorridor
    nfVehicle: this._formBuilder.control({ value: 0, disabled: true }),
    vfHallCorridor: this._formBuilder.control({ value: 0, disabled: true }),
    percentage: this._formBuilder.control({ value: 0, disabled: true }),
  });

  public projectForm: FormGroup<ProjectForm> = this._formBuilder.group({
    id: this._formBuilder.control<string | null>(null),
    name: this._formBuilder.control<string | null>(null),
    number: this._formBuilder.control<string | null>(null),
    applicationNumber: this._formBuilder.control<string | null>(null),
    description: this._formBuilder.control<string | null>(null),
    addressStreet: this._formBuilder.control<string | null>(null),
    addressNumber: this._formBuilder.control<string | null>(null),
    addressStreetAdditionalInfo: this._formBuilder.control<string | null>(null),
    addressPostCode: this._formBuilder.control<string | null>(null),
    addressCity: this._formBuilder.control<string | null>(null),
    addressState: this._formBuilder.control<string | null>(null),
    addressCountry: this._formBuilder.control<string | null>(null),
    costs: this._formBuilder.control<number | null>(null),
    projectImagePath: this._formBuilder.control<string | null>(null),

    lifeCyclePhase: this._formBuilder.control<LifeCyclePhaseEnum | null>({ value: null, disabled: true }),
    certificationType: this._formBuilder.control<CertificationTypeEnum | null>(null),
    certificationVersion: this._formBuilder.control<string | null>(null),
    // // Tab Leistungsphasen
    leistungsPhasen: this._formBuilder.array([]),
    handoverDate: this._formBuilder.control<Date | null>(null),
    submissionDate: this._formBuilder.control<Date | null>(null),

    hasMixedUsageProfiles: this._formBuilder.control<boolean | null>(false),
    usageProfiles: this._formBuilder.array([]),
    numberOfFloors: this._formBuilder.control<number | null>(null),
    numberOfUndergroundFloors: this._formBuilder.control<number | null>(null),
    numberOfParkingSpaces: this._formBuilder.control<number | null>(null),
    numberOfResidentialUnits: this._formBuilder.control<number | null>(null),
    numberOfWorkspaces: this._formBuilder.control<number | null>(null),
    numberOfUsers: this._formBuilder.control<number | null>(null),
    bwzNr: this._formBuilder.control<number | null>(null),
    lcaClass: this._formBuilder.control<string | null>(null),
    typeOfCommercialSpaces: this._formBuilder.control<string | null>(null),
    qng: this._formBuilder.control<boolean>(false),
    euTaxonomy: this._formBuilder.control<boolean>(false),
    projectOwner: this._formBuilder.control<string | null>(null),
    creator: this._formBuilder.control<string | null>(null),
    auditor: this._formBuilder.control<string | null>(null),
    supporter: this._formBuilder.control<string | null>(null),

    dgnbSystem: this._formBuilder.control<string | null>(null),
    status: this._formBuilder.control<ProjectStatusEnum | null>(null),
    bgfBigger5000: this._formBuilder.control<boolean | null>(null),
    bgfSmaller5000: this._formBuilder.control<boolean | null>(null),
    withDeconstruction: this._formBuilder.control<boolean | null>(null),
    withoutDeconstruction: this._formBuilder.control<boolean | null>(null),
  });

  get leistungsPhasen() {
    return this.projectForm.get('leistungsPhasen') as FormArray;
  }

  get usageProfiles(): FormArray {
    return this.projectForm.get('usageProfiles') as FormArray;
  }

  public constructor(
    public projectService: ProjectService,
    private _formBuilder: FormBuilder,
    private _uploadCroppedImageDialog: MatDialog,
    private _storageService: StorageService,
    private analyticsService: AnalyticsService,
    private _indicatorService: IndicatorService
  ) {
    super();
  }

  ngOnInit() {
    this.isLoading$.next(true);
    this.projectService.project$.subscribe((project: Project | null) => {
      if (project) {
        this.projectService.projectUser$.pipe().subscribe(user => {
          if (user) {
            this.isLoading$.next(false);
            // FIXME: This is a really bad solution. ProjectUser should always be set before a project can be opened.
            // Loading the ProjectUser therefore needs to be awaited in projectservice
            // FIXME: this also causes the bug, that a switch between logged in users is not propagated properly. IMPORTANT!
            this.projectForm = this._buildFormFromData(project);

            project.projectImagePath && this._loadProjectImage(project.projectImagePath);
            this.leistungsPhasen.controls.forEach((control, index) =>
              control.setValidators(lphDateNotBeforePreviousValidator(index, this.leistungsPhasen, this.projectForm.get('handoverDate')! as FormControl))
            );
            this.leistungsPhasen.valueChanges.subscribe(() => {
              this.leistungsPhasen.controls.forEach(lph => {
                lph.updateValueAndValidity({
                  emitEvent: false,
                });
              });
            });
            this._setListenerAndCalculateCombinedValues();
            this._calculateTotalForm();
            this._setListenerOnHandoverDateAndValidateLeistungsphasen();
          }
        });
      }
    });
  }

  public getUsageProfilesFormGroupAt(index: number): FormGroup {
    return (this.projectForm.get('usageProfiles') as FormArray).at(index) as FormGroup;
  }

  applyFilter(_filterValue: string) {
    this.filterValue = _filterValue.trim().toLowerCase();
  }

  public update() {
    this.analyticsService.sendEvent('button_click', {
      label: 'project-info-page_button_save',
    });
    if (this.projectService.project$.value?.id) {
      const _updatedProject: Project = {
        ...this.projectForm.getRawValue(),
        leistungsPhasen: this.leistungsPhasen.controls.map(control => (control.value ? control.value.valueOf() : null)), // convert Moment to timestamp
        handoverDate: this.projectForm.get('handoverDate')?.value ? this.projectForm.get('handoverDate')!.value!.valueOf() : null, // convert Moment to timestamp
        submissionDate: this.projectForm.get('submissionDate')?.value ? this.projectForm.get('submissionDate')!.value!.valueOf() : null,
      } as Project;
      // make sure no undefined fields are beeing updated. "null" is okay - just "undefined" is not allowed
      Object.keys(_updatedProject).forEach((key: string) => {
        const _key: keyof Project = key as keyof Project;
        if (_updatedProject[_key] === undefined) {
          delete _updatedProject[_key];
        }
      });
      this.isLoading$.next(true);
      this.projectService.updateProject(this.projectService.project$.value?.id, _updatedProject).subscribe(() => this.isLoading$.next(false));
    } else {
      throw new Error('Project id does not exist');
    }
  }

  public scenario() {}

  public editProjectImage() {
    const dialogRef = this._uploadCroppedImageDialog.open<UploadCroppedImageDialogComponent, UploadCroppedImageDialogComponentConfig, string | undefined>(
      UploadCroppedImageDialogComponent,
      {
        width: '90vw',
        maxWidth: '90vw',
        height: '90vh',
        data: {
          uploadPath: `projects/${this.projectService.project$.value!.id}/projectImage/${new Date().valueOf()}`,
          cropperMinWidth: 300,
          cropperMaxWidth: 1000,
          roundCropper: false,
          aspectRatio: 1.4,
          maintainAspectRatio: true,
        },
      }
    );

    dialogRef.afterClosed().subscribe((resultPath: string | undefined) => {
      if (resultPath) {
        this.projectForm.get('projectImagePath')!.setValue(resultPath);
        this._loadProjectImage(resultPath);
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _buildFormFromData(project: Project): FormGroup<ProjectForm> {
    const numRegex = /^-?\d*[.,]?\d{0,2}$/;
    const _form = this._formBuilder.group({
      id: this._formBuilder.control(project.id || null),
      // // -- Tab Allgemein
      name: this._formBuilder.control(project.name || null),
      number: this._formBuilder.control(project.number || null),
      applicationNumber: this._formBuilder.control(project.applicationNumber || null),
      description: this._formBuilder.control(project.description || null),
      addressStreet: this._formBuilder.control(project.addressStreet || null),
      addressNumber: this._formBuilder.control(project.addressNumber || null),
      addressStreetAdditionalInfo: this._formBuilder.control(project.addressStreetAdditionalInfo || null),
      addressPostCode: this._formBuilder.control(project.addressPostCode || null),
      addressCity: this._formBuilder.control(project.addressCity || null),
      addressState: this._formBuilder.control(project.addressState || null),
      addressCountry: this._formBuilder.control(project.addressCountry || null),
      costs: this._formBuilder.control(project.costs || null),
      projectImagePath: this._formBuilder.control(project.projectImagePath || null),
      // // // -- Tab Leistungsphasen
      leistungsPhasen: this._formBuilder.array(project.leistungsPhasen!.map((phase: number) => this._formBuilder.control(phase ? new Date(phase) : null))),
      handoverDate: this._formBuilder.control(project.handoverDate ? new Date(project.handoverDate) : null),
      // // -- Tab Zertifizierung
      lifeCyclePhase: this._formBuilder.control({ value: project.lifeCyclePhase || null, disabled: true }),
      // // Mischnutzung
      hasMixedUsageProfiles: this._formBuilder.control({ value: project.usageProfiles.length > 1, disabled: true }),
      usageProfiles: this._formBuilder.array(
        project.usageProfiles.map((profile: MixedUsageProfile) => {
          const _adgnb = (Number(profile.nuf) || 0) - (Number(profile.nfVehicle) || 0) + (Number(profile.vfHallCorridor) || 0);
          const _nrf = Number(profile.nuf) + Number(profile.tf) + Number(profile.vf);
          const _bgf = Number(profile.kgf) + Number(_nrf);
          return this._formBuilder.group({
            isMainUsage: this._formBuilder.control(profile.isMainUsage),
            usageProfile: this._formBuilder.control({ value: profile.usageProfile, disabled: true }),
            bgf: this._formBuilder.control({ value: Math.round(_bgf * 100) / 100 || 0.0, disabled: true }), // Combined Value of kgf + nrf
            bri: this._formBuilder.control(profile.bri || 0.0, Validators.pattern(numRegex)),
            nrf: this._formBuilder.control({ value: Math.round((profile.nrf ?? 0) * 100) / 100 || 0.0, disabled: true }), // Combined Value of nuf + tf + vf
            nuf: this._formBuilder.control(profile.nuf || 0.0, Validators.pattern(numRegex)),
            tf: this._formBuilder.control(profile.tf || 0.0, Validators.pattern(numRegex)),
            vf: this._formBuilder.control(profile.vf || 0.0, Validators.pattern(numRegex)),
            kgf: this._formBuilder.control(profile.kgf || 0.0, Validators.pattern(numRegex)),
            adgnb: this._formBuilder.control({ value: Math.round(_adgnb * 100) / 100 || 0.0, disabled: true }), // Combined Value of NUF -nfVehicle + vfHallCorridor
            nfVehicle: this._formBuilder.control(profile.nfVehicle || 0.0, Validators.pattern(numRegex)),
            vfHallCorridor: this._formBuilder.control(profile.vfHallCorridor || 0.0, Validators.pattern(numRegex)),
            percentage: this._formBuilder.control({ value: profile.percentage || 0.0, disabled: true }), // Percentage of bgf in correlation to total bgf
          });
        })
      ),
      numberOfFloors: this._formBuilder.control(project.numberOfFloors || null),
      numberOfUndergroundFloors: this._formBuilder.control(project.numberOfUndergroundFloors || null),
      numberOfParkingSpaces: this._formBuilder.control(project.numberOfParkingSpaces || null),
      numberOfResidentialUnits: this._formBuilder.control(project.numberOfResidentialUnits || null),
      numberOfWorkspaces: this._formBuilder.control(project.numberOfWorkspaces || null),
      numberOfUsers: this._formBuilder.control(project.numberOfUsers || null),
      bwzNr: this._formBuilder.control(project.bwzNr || null),
      lcaClass: this._formBuilder.control(project.lcaClass || null),
      typeOfCommercialSpaces: this._formBuilder.control(project.typeOfCommercialSpaces || null),

      // Art der Zertifizierung
      certificationType: this._formBuilder.control(project.certificationType || null),
      certificationVersion: this._formBuilder.control({ value: project.certificationVersion || null, disabled: true }),
      submissionDate: this._formBuilder.control(project.submissionDate ? new Date(project.submissionDate) : null),
      qng: this._formBuilder.control(project.qng || false),
      euTaxonomy: this._formBuilder.control(project.euTaxonomy || false),
      projectOwner: this._formBuilder.control({
        value: project.projectOwner,
        disabled: this.projectService.projectUser$.value?.id === project.projectOwner ? false : true,
      }),
      // projectOwner: this._formBuilder.control({ value: project.projectOwner, disabled: true }),
      creator: this._formBuilder.control(project.creator || null),
      auditor: this._formBuilder.control(project.auditor || null),
      supporter: this._formBuilder.control(project.supporter || null),

      dgnbSystem: this._formBuilder.control<string | null>(project.dgnbSystem || null),
      status: this._formBuilder.control<ProjectStatusEnum | null>(project.status || null),
      bgfBigger5000: this._formBuilder.control<boolean | null>(project.bgfBigger5000 || null),
      bgfSmaller5000: this._formBuilder.control<boolean | null>(project.bgfSmaller5000 || null),
      withDeconstruction: this._formBuilder.control<boolean | null>(project.withDeconstruction || null),
      withoutDeconstruction: this._formBuilder.control<boolean | null>(project.withoutDeconstruction || null),
    });

    return _form as FormGroup<ProjectForm>;
  }

  private _setListenerAndCalculateCombinedValues() {
    for (let index = 0; index < this.usageProfiles.controls.length; index++) {
      const group: FormGroup<UsageProfileForm> = this.usageProfiles.controls[index] as FormGroup<UsageProfileForm>;

      // set percentage
      this.totalForm
        .get('adgnb')!
        .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$))
        .subscribe(() => {
          this._recalculatePercentages();
        });

      ///////////////////////// set bgf
      group
        .get('nrf')!
        .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$))
        .subscribe(() => {
          const _sum: number = Number(group.get('nrf')!.value) + Number(group.get('kgf')!.value);
          const _roundedSum: number = Math.round(_sum * 100) / 100;
          group.get('bgf')!.setValue(_roundedSum);
        });
      group
        .get('kgf')!
        .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$))
        .subscribe(() => {
          const _sum: number = Number(group.get('nrf')!.value) + Number(group.get('kgf')!.value);
          const _roundedSum: number = Math.round(_sum * 100) / 100;
          group.get('bgf')!.setValue(_roundedSum);
        });
      ///////////////////////// set nrf
      group
        .get('nuf')!
        .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$))
        .subscribe(() => {
          const _sum: number = Number(group.get('nuf')!.value) + Number(group.get('tf')!.value) + Number(group.get('vf')!.value);
          const _roundedSum: number = Math.round(_sum * 100) / 100;
          group.get('nrf')!.setValue(_roundedSum);
        });
      group
        .get('tf')!
        .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$))
        .subscribe(() => {
          const _sum: number = Number(group.get('nuf')!.value) + Number(group.get('tf')!.value) + Number(group.get('vf')!.value);
          const _roundedSum: number = Math.round(_sum * 100) / 100;
          group.get('nrf')!.setValue(_roundedSum);
        });
      group
        .get('vf')!
        .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$))
        .subscribe(() => {
          const _sum: number = Number(group.get('nuf')!.value) + Number(group.get('tf')!.value) + Number(group.get('vf')!.value);
          const _roundedSum: number = Math.round(_sum * 100) / 100;
          group.get('nrf')!.setValue(_roundedSum);
        });
      ///////////////////////// set adgnb
      group
        .get('nuf')!
        .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$))
        .subscribe(() => {
          const _sum: number = Number(group.get('nuf')!.value) - Number(group.get('nfVehicle')!.value) + Number(group.get('vfHallCorridor')!.value);
          const _roundedSum: number = Math.round(_sum * 100) / 100;
          group.get('adgnb')!.setValue(_roundedSum);
        });
      group
        .get('nfVehicle')!
        .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$))
        .subscribe(() => {
          const _sum: number = Number(group.get('nuf')!.value) - Number(group.get('nfVehicle')!.value) + Number(group.get('vfHallCorridor')!.value);
          const _roundedSum: number = Math.round(_sum * 100) / 100;
          group.get('adgnb')!.setValue(_roundedSum);
        });
      group
        .get('vfHallCorridor')!
        .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$))
        .subscribe(() => {
          const _sum: number = Number(group.get('nuf')!.value) - Number(group.get('nfVehicle')!.value) + Number(group.get('vfHallCorridor')!.value);
          const _roundedSum: number = Math.round(_sum * 100) / 100;
          group.get('adgnb')!.setValue(_roundedSum);
        });
    }

    // set TotalForm and calculate all UsageProfiles Percentages
    this.usageProfiles.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.stop$)).subscribe(() => {
      this._calculateTotalForm();
    });
  }

  private _setListenerOnHandoverDateAndValidateLeistungsphasen(): void {
    this.projectForm.get('handoverDate')!.valueChanges.subscribe(() => {
      this.leistungsPhasen.controls.forEach(control => {
        this.leistungsPhasen.updateValueAndValidity();
      });
    });
    this.projectForm.markAllAsTouched();
  }

  private _calculateTotalForm() {
    // TODO: use one variable and iterate over all fields and use dynamic name to get single fields and set total field
    let _sumBgf: number = 0;
    let _sumBri: number = 0;
    let _sumNrf: number = 0;
    let _sumNuf: number = 0;
    let _sumTf: number = 0;
    let _sumVf: number = 0;
    let _sumKgf: number = 0;
    let _sumAdgnb: number = 0;
    let _sumNfVehicle: number = 0;
    let _sumVfHallCorridor: number = 0;
    let _sumpercentage: number = 0;
    this.usageProfiles.controls.forEach(profileFormGroup => {
      _sumBgf += Number(profileFormGroup.get('bgf')!.value);
      _sumBri += Number(profileFormGroup.get('bri')!.value);
      _sumNrf += Number(profileFormGroup.get('nrf')!.value);
      _sumNuf += Number(profileFormGroup.get('nuf')!.value);
      _sumTf += Number(profileFormGroup.get('tf')!.value);
      _sumVf += Number(profileFormGroup.get('vf')!.value);
      _sumKgf += Number(profileFormGroup.get('kgf')!.value);
      _sumAdgnb += Number(profileFormGroup.get('adgnb')!.value);
      _sumNfVehicle += Number(profileFormGroup.get('nfVehicle')!.value);
      _sumVfHallCorridor += Number(profileFormGroup.get('vfHallCorridor')!.value);
      _sumpercentage += Number(profileFormGroup.get('percentage')!.value);
    });
    this.totalForm.get('bgf')!.setValue(Math.round(_sumBgf * 100) / 100);
    this.totalForm.get('bri')!.setValue(Math.round(_sumBri * 100) / 100, { emitEvent: false });
    this.totalForm.get('nrf')!.setValue(Math.round(_sumNrf * 100) / 100, { emitEvent: false });
    this.totalForm.get('nuf')!.setValue(Math.round(_sumNuf * 100) / 100, { emitEvent: false });
    this.totalForm.get('tf')!.setValue(Math.round(_sumTf * 100) / 100, { emitEvent: false });
    this.totalForm.get('vf')!.setValue(Math.round(_sumVf * 100) / 100, { emitEvent: false });
    this.totalForm.get('kgf')!.setValue(Math.round(_sumKgf * 100) / 100, { emitEvent: false });
    this.totalForm.get('adgnb')!.setValue(Math.round(_sumAdgnb * 100) / 100);
    this.totalForm.get('nfVehicle')!.setValue(Math.round(_sumNfVehicle * 100) / 100, { emitEvent: false });
    this.totalForm.get('vfHallCorridor')!.setValue(Math.round(_sumVfHallCorridor * 100) / 100, { emitEvent: false });
    this.totalForm.get('percentage')!.setValue(Math.round(_sumpercentage * 100) / 100, { emitEvent: false });
  }

  private _recalculatePercentages() {
    for (let index = 0; index < this.usageProfiles.controls.length; index++) {
      const group: FormGroup<UsageProfileForm> = this.usageProfiles.controls[index] as FormGroup<UsageProfileForm>;
      if (Number(this.totalForm.get('adgnb')!.value) === 0) {
        group.get('percentage')!.setValue(0);
      } else {
        const _sum: number = (Number(group.get('adgnb')!.value) / Number(this.totalForm.get('adgnb')!.value)) * 100;
        const _roundedSum: number = Math.round(_sum * 100) / 100;
        group.get('percentage')!.setValue(_roundedSum);
      }
    }
  }

  private _loadProjectImage(path: string) {
    if (path) {
      this._storageService.downloadImage(path).subscribe(img => this.projectImage$.next(img));
    }
  }
}
