<div class="center option">
    <mat-card class="card">
        <mat-card-header>
            <mat-card-title>
                <h1>Enterprise</h1>
            </mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <h1>1500 €</h1> <b>Benutzer / Jahr</b>
            <mat-divider></mat-divider>

            <b>Das Enterprise-Paket beinhaltet:</b>
            <p>Zugriff auf alle aktiven & inaktiven Projekte</p>
            <p>EVA</p>
            <p>Cockpit</p>
            <p>Insights</p>
            <p>unbegrenzte Benutzerzahl</p>
            <p>Dateiablage 200 GB</p>
            <p>Mobile App</p>
        </mat-card-content>
    </mat-card>

    <button id="button-cancel" mat-raised-button color="warn" (click)="cancel()">Enterprise kündigen</button>
</div>

<button id="button-checkout" mat-raised-button color="primary" (click)="checkout()" [disabled]="true">Weiter zur
    Kasse</button>