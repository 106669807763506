

/**
 * @deprecated unnecessary
 * 
 * @export
 * @enum {number}
 */
export enum CertificationVersionEnum {
    V2023 = 'Version 2023'
}

