import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { GeneralTitleComponent } from '../../core/components/general-title/general-title.component';

@Component({
  selector: 'eule-home',
  standalone: true,
  imports: [CommonModule, MatCardModule, GeneralTitleComponent],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {

}
