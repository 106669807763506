<div class="flexbox direction-column centered smaller-gap">
  <mat-card class="custom-card semi-round shadow-1">
    <mat-card-header>
      <mat-card-title>Verifizierungsmail erneut versenden</mat-card-title>
      <!-- <mat-card-subtitle></mat-card-subtitle> -->
    </mat-card-header>
    <mat-card-content>
      <button mat-flat-button color="primary" class="rounded" (click)="send()" [disabled]="buttonDisabled || !authUser">
        <span class="button-label">Email-Verifizierungslink erneut senden</span>
      </button>
    </mat-card-content>
  </mat-card>
  <div class="to-login">
    <span>Email bereits verifiziert? Hier geht es </span>
    <a class="conditions accent" [routerLink]="'/login'">zur Anmeldung</a>
  </div>
</div>
