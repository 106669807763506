<div class="project-info-page flexbox direction-column">
  <eule-general-title [title]="'Projektinfo'">
    @if ((isLoading$ | async) === false) {
    <div class="flexbox align-center default-gap">
      <button mat-raised-button color="primary" [disabled]="projectForm.invalid" (click)="update()">
        <mat-icon>save</mat-icon>
        Speichern
      </button>
    </div>
    }
  </eule-general-title>

  @if (isLoading$ | async) {
  <mat-spinner class="center"></mat-spinner>
  } @else {
  <mat-card class="custom-card w-100-percent">
    <mat-card-content>
      <div [formGroup]="projectForm" class="container">
        <mat-tab-group dynamicHeight class="w-100-percent">
          <!-- TAB Allgemein -->
          <mat-tab label="Allgemein">
            <div class="mat-elevation-z4">
              <div class="row tab-content">
                <div class="col padding-right">
                  <mat-form-field class="width-100 smaller-font">
                    <mat-label>Projektname</mat-label>
                    <input matInput formControlName="name" />
                  </mat-form-field>
                  <mat-form-field class="width-100 smaller-font">
                    <mat-label>Projektnummer</mat-label>
                    <input matInput formControlName="number" />
                  </mat-form-field>
                  <mat-form-field class="width-100 smaller-font">
                    <mat-label>Antragsnummer</mat-label>
                    <input matInput formControlName="applicationNumber" />
                  </mat-form-field>
                  <mat-form-field class="width-100 smaller-font">
                    <mat-label>Baukosten in €</mat-label>
                    <eule-comma-decimal-input formControlName="costs"></eule-comma-decimal-input>
                  </mat-form-field>
                  <mat-form-field class="width-100 smaller-font">
                    <mat-label>Beschreibung</mat-label>
                    <textarea
                      matInput
                      cdkTextareaAutosize
                      formControlName="description"
                      #autosize="cdkTextareaAutosize"
                      cdkAutosizeMinRows="11"
                      cdkAutosizeMaxRows="11"
                    ></textarea>
                  </mat-form-field>
                </div>
                <div class="col">
                  <div class="project-image-container center">
                    <img id="project-image" [src]="projectImage$ | async" alt="'picture of house'" />
                    <button mat-icon-button (click)="editProjectImage()" class="hover-button">
                      <mat-icon>upload</mat-icon>
                    </button>
                  </div>

                  <div class="row">
                    <mat-form-field class="width-75 smaller-font street">
                      <mat-label>Straße</mat-label>
                      <input matInput formControlName="addressStreet" />
                    </mat-form-field>
                    <mat-form-field class="width-25 smaller-font">
                      <mat-label>Hausnummer</mat-label>
                      <input matInput formControlName="addressNumber" />
                    </mat-form-field>
                  </div>
                  <div class="row">
                    <mat-form-field class="width-75 smaller-font city">
                      <mat-label>Ort</mat-label>
                      <input matInput formControlName="addressCity" />
                    </mat-form-field>
                    <mat-form-field class="width-25 smaller-font">
                      <mat-label>Postleitzahl</mat-label>
                      <input matInput formControlName="addressPostCode" />
                    </mat-form-field>
                  </div>
                  <mat-form-field class="width-100 smaller-font">
                    <mat-label>Bundesland</mat-label>
                    <input matInput formControlName="addressState" />
                  </mat-form-field>
                  <mat-form-field class="width-100 smaller-font">
                    <mat-label>Land</mat-label>
                    <input matInput formControlName="addressCountry" />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-tab>

          <!-- Tab Leistungsphasen -->
          <mat-tab>
            <ng-template mat-tab-label>
              <span>Leistungsphasen</span>
              @if (leistungsPhasen.errors) {
              <mat-icon class="info" [matTooltip]="'Dieser Tab enthält falsch ausgefüllte Felder'"> info </mat-icon>
              }
            </ng-template>
            <div class="mat-elevation-z4">
              <div class="row tab-content">
                <div class="col padding-right">
                  <ng-container formArrayName="leistungsPhasen">
                    @for (phase of leistungsPhasen.controls; track phase) { @if ($index < 5) {
                    <mat-form-field class="width-100 smaller-font">
                      <mat-label>Beginn {{ staticLeistungsphasen[$index].title }}</mat-label>
                      <input matInput formControlName="{{ $index }}" [matDatepicker]="dp" />
                      <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
                      <mat-datepicker #dp></mat-datepicker>
                      @if (phase?.hasError('lphDatebefore')) {
                      <mat-error>Datum muss vor nachfolgenden und nach vorherigen Lph liegen</mat-error>
                      }
                    </mat-form-field>
                    } }
                  </ng-container>
                </div>
                <div class="col">
                  <ng-container formArrayName="leistungsPhasen">
                    @for (phase of leistungsPhasen.controls; track phase) { @if ($index >= 5) {
                    <mat-form-field class="width-100 smaller-font">
                      <mat-label>Beginn {{ staticLeistungsphasen[$index].title }}</mat-label>
                      <input matInput formControlName="{{ $index }}" [matDatepicker]="dp" />
                      <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
                      <mat-datepicker #dp></mat-datepicker>
                      @if (phase?.hasError('lphDatebefore')) {
                      <mat-error>Datum muss vor nachfolgenden und nach vorherigen Lph liegen</mat-error>
                      }
                    </mat-form-field>
                    } }
                  </ng-container>
                  <mat-form-field class="width-100 smaller-font">
                    <mat-label>Übergabedatum</mat-label>
                    <input matInput formControlName="handoverDate" [matDatepicker]="dp" />
                    <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
                    <mat-datepicker #dp></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Zertifizierung">
            <div class="mat-elevation-z4">
              <div class="col">
                <div class="row">
                  <!-- lifeCyclePhase cannot be changed! Needs to be disabled -->
                  <mat-form-field class="w-100-percent smaller-font">
                    <mat-label>Bauphase</mat-label>
                    <mat-select formControlName="lifeCyclePhase">
                      @for (phase of lifeCyclePhaseEnum | keyvalue; track phase) {
                      <mat-option [value]="phase.key">{{ phase.value }}</mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-label>Mischnutzung</mat-label>
                  <mat-slide-toggle formControlName="hasMixedUsageProfiles" #mixedUsageProfiles></mat-slide-toggle>
                  <mat-icon fontIcon="info" [matTooltip]="tooltips['hasMixedUsageProfiles']"></mat-icon>
                </div>
                <ng-container formArrayName="usageProfiles">
                  @for (profile of usageProfiles.controls; track profile) {
                  <ng-container [formGroup]="getUsageProfilesFormGroupAt($index)">
                    @if ($index === 0) {
                    <div class="row">
                      <mat-form-field class="w-100-percent smaller-font">
                        <mat-label>Hauptnutzung</mat-label>
                        <mat-select formControlName="usageProfile">
                          @for (profileType of projectService.usageProfiles$ | async; track profileType) {
                          <mat-option [value]="profileType.id">{{ usageProfileEnum[profileType.name] }}</mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                      <mat-icon fontIcon="info" [matTooltip]="tooltips['mainUsage']"></mat-icon>
                    </div>
                    } @else {
                    <div class="row">
                      <mat-form-field class="w-100-percent smaller-font">
                        <mat-label>Nebennutzung</mat-label>
                        <mat-select formControlName="usageProfile">
                          @for (profileType of projectService.usageProfiles$ | async; track profileType) {
                          <mat-option [value]="profileType.id">{{ usageProfileEnum[profileType.name] }}</mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                      <mat-icon fontIcon="info" [matTooltip]="tooltips['sideUsage']"></mat-icon>
                    </div>
                    }
                  </ng-container>
                  }
                </ng-container>

                <div class="row">
                  <mat-form-field class="w-100-percent smaller-font">
                    <mat-label>Zertifizierungsversion</mat-label>
                    <mat-select formControlName="certificationVersion">
                      @for (certificationVersion of certificationVersionEnum | keyvalue; track certificationVersion) {
                      <mat-option [value]="certificationVersion.key">{{ certificationVersion.value }}</mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="width-100 smaller-font">
                    <mat-label>Einreichdatum</mat-label>
                    <input matInput formControlName="submissionDate" [matDatepicker]="submissionDatePicker" />
                    <mat-datepicker-toggle matIconSuffix [for]="submissionDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #submissionDatePicker></mat-datepicker>
                  </mat-form-field>
                </div>

                <div class="row">
                  <mat-form-field class="w-100-percent smaller-font">
                    <mat-label>QNG</mat-label>
                    <mat-select formControlName="qng">
                      @for (qng of booleanEnum | keyvalue; track qng) {
                      <mat-option [value]="qng.key">{{ qng.value }}</mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field class="w-100-percent smaller-font">
                    <mat-label>Eu-Taxonomie</mat-label>
                    <mat-select formControlName="euTaxonomy">
                      @for (euTaxonomy of booleanEnum | keyvalue; track euTaxonomy) {
                      <mat-option [value]="euTaxonomy.key">{{ euTaxonomy.value }}</mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Gebäudeparameter">
            <!-- TOTAL Parameters of all UsageProfiles -->
            <div class="col">
              <div class="row">
                <h2 class="bordered default-border-color">Allgemein</h2>
              </div>
              <div class="row">
                <mat-form-field class="w-100-percent smaller-font">
                  <mat-label>Anzahl Geschosse</mat-label>
                  <eule-comma-decimal-input formControlName="numberOfFloors"></eule-comma-decimal-input>
                </mat-form-field>
                <mat-form-field class="w-100-percent smaller-font">
                  <mat-label>Anzahl Untergeschosse</mat-label>
                  <eule-comma-decimal-input formControlName="numberOfUndergroundFloors"></eule-comma-decimal-input>
                </mat-form-field>
                <mat-form-field class="w-100-percent smaller-font">
                  <mat-label>Anzahl PKW-Stellplätze</mat-label>
                  <eule-comma-decimal-input formControlName="numberOfParkingSpaces"></eule-comma-decimal-input>
                </mat-form-field>
                <mat-form-field class="w-100-percent smaller-font">
                  <mat-label>Anzahl Wohneinheiten</mat-label>
                  <eule-comma-decimal-input formControlName="numberOfResidentialUnits"></eule-comma-decimal-input>
                </mat-form-field>
              </div>

              <div class="row">
                <mat-form-field class="w-100-percent smaller-font">
                  <mat-label>Anzahl Arbeitsplätze</mat-label>
                  <eule-comma-decimal-input formControlName="numberOfWorkspaces"></eule-comma-decimal-input>
                </mat-form-field>
                <mat-form-field class="w-100-percent smaller-font">
                  <mat-label>Anzahl Nutzer</mat-label>
                  <eule-comma-decimal-input formControlName="numberOfUsers"></eule-comma-decimal-input>
                </mat-form-field>
                <mat-form-field class="w-100-percent smaller-font">
                  <mat-label>BWZ-Nr.</mat-label>
                  <input matInput formControlName="bwzNr" />
                </mat-form-field>
                <mat-form-field class="w-100-percent smaller-font">
                  <mat-label>LCA-Klasse</mat-label>
                  <input matInput formControlName="lcaClass" />
                </mat-form-field>
              </div>

              <div class="row">
                <mat-form-field class="w-100-percent smaller-font">
                  <mat-label>Art der Gewerbeflächen</mat-label>
                  <input matInput formControlName="typeOfCommercialSpaces" />
                </mat-form-field>
              </div>
              <ng-container [formGroup]="totalForm">
                <h2 class="bordered default-border-color">Bemessungsgrößen</h2>
                <h3 class="bordered default-border-color">Gesamt</h3>
                <div class="row">
                  <h4>Flächennutzung nach DIN277</h4>
                  <mat-icon fontIcon="info" [matTooltip]="tooltips['flaechenberechnungNachDin277']"></mat-icon>
                </div>
                <div class="row">
                  <mat-form-field class="w-100-percent smaller-font">
                    <mat-label>BGF (R)</mat-label>
                    <eule-comma-decimal-input formControlName="bgf"></eule-comma-decimal-input>
                  </mat-form-field>
                  <mat-form-field class="w-100-percent smaller-font">
                    <mat-label>BRI</mat-label>
                    <eule-comma-decimal-input formControlName="bri"></eule-comma-decimal-input>
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field class="w-100-percent smaller-font">
                    <mat-label>NRF (R)</mat-label>
                    <eule-comma-decimal-input formControlName="nrf"></eule-comma-decimal-input>
                  </mat-form-field>
                  <mat-form-field class="w-100-percent smaller-font">
                    <mat-label>NUF (R)</mat-label>
                    <eule-comma-decimal-input formControlName="nuf"></eule-comma-decimal-input>
                  </mat-form-field>
                  <mat-form-field class="w-100-percent smaller-font">
                    <mat-label>TF (R)</mat-label>
                    <eule-comma-decimal-input formControlName="tf"></eule-comma-decimal-input>
                  </mat-form-field>
                  <mat-form-field class="w-100-percent smaller-font">
                    <mat-label>VF (R)</mat-label>
                    <eule-comma-decimal-input formControlName="vf"></eule-comma-decimal-input>
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field class="w-100-percent smaller-font">
                    <mat-label>KGF (R)</mat-label>
                    <input matInput formControlName="kgf" />
                  </mat-form-field>
                </div>
                <div class="row">
                  <h3>DGNB-Bemessungsfläche</h3>
                  <mat-icon fontIcon="info" [matTooltip]="tooltips['bemessungsflaeche']"></mat-icon>
                </div>
                <div class="row">
                  <mat-form-field class="w-100-percent smaller-font">
                    <mat-label>ADGNB (R)</mat-label>
                    <eule-comma-decimal-input formControlName="adgnb"></eule-comma-decimal-input>
                  </mat-form-field>
                  <mat-form-field class="w-100-percent smaller-font">
                    <mat-label>NF Fahrzeug</mat-label>
                    <eule-comma-decimal-input formControlName="nfVehicle"></eule-comma-decimal-input>
                  </mat-form-field>
                  <mat-form-field class="w-100-percent smaller-font">
                    <mat-label>VF Hallen,Flure</mat-label>
                    <eule-comma-decimal-input formControlName="vfHallCorridor"></eule-comma-decimal-input>
                  </mat-form-field>
                  <mat-form-field class="w-100-percent smaller-font">
                    <mat-label>Anteil in %</mat-label>
                    <eule-comma-decimal-input formControlName="percentage"></eule-comma-decimal-input>
                  </mat-form-field>
                </div>
              </ng-container>
            </div>

            <!-- UsageProfiles Parameters -->
            <ng-container formArrayName="usageProfiles">
              @for (profile of usageProfiles.controls; track profile) {
              <ng-container [formGroup]="getUsageProfilesFormGroupAt($index)">
                <div class="col">
                  @if ($index === 0) {
                  <h2 class="bordered default-border-color">Hauptnutzung</h2>
                  } @else {
                  <h2 class="bordered default-border-color">Nebennutzung</h2>
                  } @for (profileType of projectService.usageProfiles$ | async; track profileType) { @if (profileType.id === profile.get('usageProfile')!.value)
                  {
                  {{ usageProfileEnum[profileType.name] }}

                  <div class="row">
                    <h4>Flächennutzung nach DIN277</h4>
                    <mat-icon fontIcon="info" [matTooltip]="tooltips['flaechenberechnungNachDin277']"></mat-icon>
                  </div>
                  <div class="row">
                    <mat-form-field class="w-100-percent smaller-font">
                      <mat-label>BGF (R)</mat-label>
                      <eule-comma-decimal-input formControlName="bgf"></eule-comma-decimal-input>
                    </mat-form-field>
                    <mat-form-field class="w-100-percent smaller-font">
                      <mat-label>BRI</mat-label>
                      <eule-comma-decimal-input formControlName="bri"></eule-comma-decimal-input>
                    </mat-form-field>
                  </div>
                  <div class="row">
                    <mat-form-field class="w-100-percent smaller-font">
                      <mat-label>NRF (R)</mat-label>
                      <eule-comma-decimal-input formControlName="nrf"></eule-comma-decimal-input>
                    </mat-form-field>
                    <mat-form-field class="w-100-percent smaller-font">
                      <mat-label>NUF (R)</mat-label>
                      <eule-comma-decimal-input formControlName="nuf"></eule-comma-decimal-input>
                    </mat-form-field>
                    <mat-form-field class="w-100-percent smaller-font">
                      <mat-label>TF (R)</mat-label>
                      <eule-comma-decimal-input formControlName="tf"></eule-comma-decimal-input>
                    </mat-form-field>
                    <mat-form-field class="w-100-percent smaller-font">
                      <mat-label>VF (R)</mat-label>
                      <eule-comma-decimal-input formControlName="vf"></eule-comma-decimal-input>
                    </mat-form-field>
                  </div>
                  <div class="row">
                    <mat-form-field class="w-100-percent smaller-font">
                      <mat-label>KGF (R)</mat-label>
                      <eule-comma-decimal-input formControlName="kgf"></eule-comma-decimal-input>
                    </mat-form-field>
                  </div>
                  <div class="row">
                    <h3>DGNB-Bemessungsfläche</h3>
                    <mat-icon fontIcon="info" [matTooltip]="tooltips['bemessungsflaeche']"></mat-icon>
                  </div>
                  <div class="row">
                    <mat-form-field class="w-100-percent smaller-font">
                      <mat-label>ADGNB (R)</mat-label>
                      <eule-comma-decimal-input formControlName="adgnb"></eule-comma-decimal-input>
                    </mat-form-field>
                    <mat-form-field class="w-100-percent smaller-font">
                      <mat-label>NF Fahrzeug</mat-label>
                      <eule-comma-decimal-input formControlName="nfVehicle"></eule-comma-decimal-input>
                    </mat-form-field>
                    <mat-form-field class="w-100-percent smaller-font">
                      <mat-label>VF Hallen,Flure</mat-label>
                      <eule-comma-decimal-input formControlName="vfHallCorridor"></eule-comma-decimal-input>
                    </mat-form-field>
                    <mat-form-field class="w-100-percent smaller-font">
                      <mat-label>Anteil in %</mat-label>
                      <eule-comma-decimal-input formControlName="percentage"></eule-comma-decimal-input>
                    </mat-form-field>
                  </div>
                  } }
                </div>
              </ng-container>
              }
            </ng-container>
          </mat-tab>
          <mat-tab label="Ansprechpartner">
            <div class="mat-elevation-z4">
              <div class="col tab-content">
                <mat-form-field>
                  <mat-label>Projekteigentümer</mat-label>
                  <mat-select formControlName="projectOwner">
                    @for (user of projectService.projectUsers$ | async; track user) {
                    <mat-option [value]="user.id">{{ user.firstName }} {{ user.lastName }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
                <mat-form-field>
                  <mat-label>Verantwortlicher</mat-label>
                  <!-- FIXME: acts like responsibleId  -->
                  <mat-select formControlName="creator">
                    @for (user of projectService.projectUsers$ | async; track user) {
                    <mat-option [value]="user.id">{{ user.firstName }} {{ user.lastName }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Auditor</mat-label>
                  <mat-select formControlName="auditor">
                    @for (user of projectService.projectUsers$ | async; track user) {
                    <mat-option [value]="user.id">{{ user.firstName }} {{ user.lastName }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>Supporter und externer Prüfer</mat-label>
                  <mat-select formControlName="supporter">
                    @for (user of projectService.projectUsers$ | async; track user) {
                    <mat-option [value]="user.id">{{ user.firstName }} {{ user.lastName }}</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </mat-card-content>
  </mat-card>
  }
</div>
