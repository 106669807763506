import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { RouterLink } from '@angular/router';
import { User, getAuth, sendEmailVerification } from 'firebase/auth';
import { AuthService } from '../../core/services/auth-christian/auth.service';

@Component({
  selector: 'eule-verify-page',
  standalone: true,
  imports: [RouterLink, MatButtonModule, MatCardModule],
  templateUrl: './verify-page.component.html',
  styleUrl: './verify-page.component.scss',
})
export class VerifyPageComponent {
  private timer: number | undefined;
  public buttonDisabled: boolean = false;
  public authUser: User | null;

  public constructor(private _authService: AuthService) {
    this.authUser = getAuth().currentUser;
    this._authService.logout({ navigate: false, message: false });
  }

  public async send() {
    this.startTimer();
    await sendEmailVerification(this.authUser!);
  }

  private startTimer() {
    if (this.timer !== undefined) {
      return;
    }
    this.buttonDisabled = !this.buttonDisabled;

    this.timer = window.setTimeout(() => {
      this.buttonDisabled = !this.buttonDisabled;
      this.stopTimer(); // Timer beenden
    }, 60000);
  }

  private stopTimer() {
    if (this.timer !== undefined) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
  }
}
