<eule-general-title [title]="'Start'">
  <div class="flexbox smaller-gap align-center w-100-percent justify-spaced">
<!--    @if (scenarioOptions$ | async; as options) {-->
<!--      <form [formGroup]="scenarioForm" (ngSubmit)="scenario()" class="scenario-picker-form">-->
<!--        <mat-form-field class="w-100-percent no-background reduced smaller-font scenario smaller-shape no-padding">-->
<!--          <mat-label>Szenario</mat-label>-->
<!--          <mat-select formControlName="scenario">-->
<!--            @for (option of options; track option) {-->
<!--              <mat-option [value]="option.value">{{ option.viewValue }}</mat-option>-->
<!--            }-->
<!--          </mat-select>-->
<!--        </mat-form-field>-->
<!--      </form>-->
<!--    }-->
    <div class="flexbox smaller-gap align-center">
      <!-- <button mat-raised-button color="primary" class="btn vertical no-shadow" (click)="apply()">
        <mat-icon>language</mat-icon>
        DGNB Sprechstunde
      </button>
      <button mat-raised-button color="accent" class="btn vertical no-shadow" (click)="scenario()">
        <mat-icon>language</mat-icon>
        Export
      </button>
      <button mat-raised-button color="accent" class="btn vertical no-shadow" (click)="scenario()">
        <mat-icon>language</mat-icon>
        Projekt anmelden
      </button> -->
    </div>
    <!--    <eule-search-bar id="search-field" [(search)]="filterValue" (searchChange)="applyFilter($event)"> </eule-search-bar>-->
    <!--    <button mat-mini-fab>-->
    <!--        <mat-icon>ios_share</mat-icon>-->
    <!--    </button>-->
    <!--    <button mat-mini-fab>-->
    <!--        <mat-icon>filter_list</mat-icon>-->
    <!--    </button>-->
  </div>
</eule-general-title>
<mat-card class="custom-card h-100-percent">
  <mat-card-content class="h-100-percent overflow-hidden">
    Folgt in Kürze..
  </mat-card-content>
</mat-card>
