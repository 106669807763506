import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { ProjectUser } from '@eeule/eeule-shared/src/types';
import { BehaviorSubject, switchMap, take, takeUntil } from 'rxjs';
import { BaseComponent } from '../../../core/components/base/base.component';
import { GeneralTitleComponent } from '../../../core/components/general-title/general-title.component';
import { SearchBarComponent } from '../../../core/components/search-bar/search-bar.component';
import { AnalyticsService } from '../../../core/services/analytics/analytics.service';
import { ProjectService } from '../../../core/services/project.service';
import { ProjectUserDisplay, UserService } from '../../../core/services/user.service';
import {
  AddUserToProjectDialogComponent,
  AddUserToProjectDialogComponentConfig,
  AddUserToProjectDialogResult,
} from '../../components/add-user-to-project-dialog/add-user-to-project-dialog.component';
import { UsersListComponent } from '../../components/users-list/users-list.component';

@Component({
  selector: 'eule-users-list-page',
  standalone: true,
  imports: [
    CommonModule,
    GeneralTitleComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTableModule,
    UsersListComponent,
    SearchBarComponent,
  ],
  templateUrl: './users-list-page.component.html',
  styleUrl: './users-list-page.component.scss',
})
export class UsersListPageComponent extends BaseComponent implements OnInit {
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public searchValue = 'clear';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public dataSource: MatTableDataSource<any> | undefined;
  public filterValue: string = '';

  @ViewChild(MatPaginator) paginator!: MatPaginator | null;
  @ViewChild(MatSort) sort!: MatSort | null;

  public constructor(
    public projectService: ProjectService,
    private _userService: UserService,
    private _router: Router,
    private _dialog: MatDialog,
    private analyticsService: AnalyticsService
  ) {
    super();
  }

  ngOnInit() {
    this._loadUsers();
  }

  public search() {
    this.searchValue = '';
  }

  applyFilter(_filterValue: string) {
    if (this.dataSource) {
      this.filterValue = _filterValue;
      this.dataSource.filter = _filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      } else {
        new Error('No DataSource for Table');
      }
    }
  }

  public openAddUserDialog() {
    this.analyticsService.sendEvent('button_click', {
      label: 'users-list-page_invite-user',
    });
    const dialogRef = this._dialog.open<AddUserToProjectDialogComponent, AddUserToProjectDialogComponentConfig, AddUserToProjectDialogResult | null>(
      AddUserToProjectDialogComponent,
      {
        width: '480px',
        maxWidth: '70vw',
        data: {},
      }
    );

    dialogRef.afterClosed()
      .pipe(take(1))
      .subscribe((result: AddUserToProjectDialogResult | null | undefined) => {
      if (result) {
        this.projectService.inviteUserByEmail(result.invitedUserEmail).subscribe();
      }
    });
  }

  public addUser() {}

  private _loadUsers() {
    this.isLoading$.next(true);
    this.projectService.project$
      .pipe(
        switchMap(project => {
          if (!project) return [];
          return this.projectService.getLiveProjectUsers(project.id)
        }),
        switchMap((users: ProjectUser[]) => {
          return this._userService.mapAuthUsersDataToProjectUsers(users);
        }),
        takeUntil(this.stop$)
      )
      .subscribe((users: ProjectUserDisplay[]) => {
        this.dataSource = new MatTableDataSource(users);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.isLoading$.next(false);
      });
  }
}
