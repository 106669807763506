  <mat-card class="custom-card">
    <mat-card-content class="small-padding">
      @if (isLoading$ | async) {
        <div class="loading-overlay covering h-100-percent card-background">
          <mat-spinner diameter="50"></mat-spinner>
        </div>
      }
      <table mat-table [dataSource]="dataSource!" matSort class="differ-rows">
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <!-- <td mat-cell *matCellDef="let row">{{ row.firstName }} {{ row.lastName }}</td> -->
          <td mat-cell *matCellDef="let row">{{ row.firstName }} {{ row.lastName }}</td>
        </ng-container>
        <!-- Title Column -->
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Position</th>
          <!-- <td mat-cell *matCellDef="let row">{{ row?.title }}</td> -->
          <td mat-cell *matCellDef="let row">{{ row.title }}</td>
        </ng-container>
        <!-- Company Column -->
        <ng-container matColumnDef="company">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Firma</th>
          <!-- <td mat-cell *matCellDef="let row">{{ row?.company }}</td> -->
          <td mat-cell *matCellDef="let row">{{ row.company }}</td>
        </ng-container>
        <!-- Phone Column -->
        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Telefon</th>
          <!-- <td mat-cell *matCellDef="let row">{{ row?.phone }}</td> -->
          <td mat-cell *matCellDef="let row">{{ row.phone }}</td>
        </ng-container>
        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>eMail</th>
          <!-- <td mat-cell *matCellDef="let row">{{ row?.email }}</td> -->
          <td mat-cell *matCellDef="let row">{{ row.email }}</td>
        </ng-container>
        <!-- License Column -->
        <ng-container matColumnDef="license">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Lizenz</th>
          <td mat-cell *matCellDef="let row">{{ row.license ? 'Ja' : 'Nein' }}</td>
        </ng-container>
        <!-- Icons Column -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Aktionen</th>
          <td mat-cell *matCellDef="let row">
            <!-- only allow users to be deleted if they are not the project Owner -->
            @if ((projectService.project$ | async)?.projectOwner !== row.id) {
              <button mat-mini-fab aria-label="Delete-Button"
                      color="warn no-shadow smaller-button"
                      (click)="deleteUserFromProject(row)"
                      matTooltip="Benutzer aus Projekt entfernen">
                <mat-icon>delete</mat-icon>
              </button
              >
            }
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter "{{ dataSource }}"</td>
        </tr>
      </table>

      <mat-paginator
        [pageSizeOptions]="[5, 10, 25, 100]"
        [pageSize]="(userService.euleUser$ | async)?.pagination"
        (page)="onChangePageSize($event.pageSize)"
        aria-label="Select page of users"
      ></mat-paginator>
    </mat-card-content>
  </mat-card>
