<h2 mat-dialog-title>Fehler melden</h2>
<mat-dialog-content>
  <div id="indicator-dialog-container">
    <form [formGroup]="bugReportForm">
      <div class="flexbox direction-column xs-gap">
        <div class="form-field-wrapper">
          <div class="form-field-info strong">
            Bitte beschreiben Sie den aufgetretenen Fehler. Wo und wann ist der Fehler aufgetreten?
          </div>
          <mat-form-field class="w-100-percent">
            <mat-label>Fehlerbeschreibung</mat-label>
            <textarea
              matInput
              formControlName="description"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="7"
              cdkAutosizeMaxRows="14"
            ></textarea>
            @if (bugReportForm.get('description')?.hasError('required')) {
              <mat-error>Dieses Feld muss einen Wert enthalten</mat-error>
            }
          </mat-form-field>
        </div>
        <div class="form-field-wrapper">
          <div class="form-field-info strong">
            Welches Verhalten haben Sie erwartet?
          </div>
          <mat-form-field class="w-100-percent">
            <mat-label>Erwartetes Verhalten</mat-label>
            <textarea
              matInput
              formControlName="expectedBehaviour"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="7"
              cdkAutosizeMaxRows="14"
            ></textarea>
            @if (bugReportForm.get('expectedBehaviour')?.hasError('required')) {
              <mat-error>Dieses Feld muss einen Wert enthalten</mat-error>
            }
          </mat-form-field>
        </div>
      <div class="form-field-wrapper">
        <div class="form-field-info strong">
          Bitte beschreiben Sie möglichst genau welches Verhalten aufgetreten ist.
        </div>
          <mat-form-field class="w-100-percent">
            <mat-label>Tatsächliches Verhalten</mat-label>
            <textarea
              matInput
              formControlName="actualBehaviour"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="7"
              cdkAutosizeMaxRows="14"
            ></textarea>
            @if (bugReportForm.get('actualBehaviour')?.hasError('required')) {
              <mat-error>Dieses Feld muss einen Wert enthalten</mat-error>
            }
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button [mat-dialog-close]="false">
    Abbrechen
  </button>
  <button mat-flat-button color="primary"
          [disabled]="!bugReportForm.dirty || !bugReportForm.valid"
          [mat-dialog-close]="true">
    Abschicken
  </button>
</mat-dialog-actions>
